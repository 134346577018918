import { Fragment, useRef, useContext } from 'react';
import { IonCard, IonCardHeader, IonCardContent, IonSelect, IonSelectOption, IonNote, IonGrid, IonRow, IonCol } from '@ionic/react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useQuery } from 'react-query';
import apiContext from '../services/api-service';
import { useAuthContext } from '../hooks/authContext';
import { urls } from '../utilities/urls';
import traits from '../utilities/trait-descriptions.json';

interface AnalyticsSelectTypes {
  Introverts: string
  Ambiverts: string
  Extroverts: string
  Agreeable: string
  Moderate: string
  Competitive: string
  Industrious: string
  Determined: string
  'Laid Back': string
  Creative: string
  Inventive: string
  Rigid: string
  Neurotic: string
  Stable: string
  Calm: string
  Humble: string
  Confident: string
  Selfish: string
  Married: string
  Single: string
  Partnered: string
}

interface AnalyticsCardTypes {
  selectOptions: AnalyticsSelectTypes
  cardId: string
  totalMembers: number
  defaultValue: string
  defaultLabel: string
}

interface SelectChangeEventDetail<T = any> {
  value: T
}

interface SelectedTypes {
  label: string
  value: string
}

const AnalyticsCard = ({selectOptions, cardId, totalMembers, defaultLabel, defaultValue}: AnalyticsCardTypes) => {

  const api = useContext(apiContext);
  const userAuth = useAuthContext();

  const selectedVal = useRef({label: defaultLabel, value: defaultValue} as SelectedTypes);

  const circleStyle = {
    textSize: '1rem'
  }

  const { refetch, data, isLoading, isFetching, isError, error } = useQuery(`analyticsData-${cardId}`, () => {
    return api.getData(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', '', userAuth.orgcode ?? '', 'analytics', `${selectedVal.current?.value}`, userAuth.userDetails?.email ?? '', userAuth.jwtRef.current)
      .then(res => res)
      .catch(err => { throw new Error(err) });
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  }
  );

  const handleChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    selectedVal.current = e.detail.value;
    refetch();
  }

  return (
    <Fragment>
      <IonCard style={{textAlign: 'center'}}>
          {/* <h2>{`${selectedVal.current?.label ?? 'Selection'}: ${data?.Count ?? 0}`}</h2> */}
          <IonGrid>
            <IonRow>
              <IonCol>
                {(data && data.Count) ? 
                  <CircularProgressbar styles={buildStyles(circleStyle)} value={(data?.Count/totalMembers) * 100} text={`${((data?.Count/totalMembers) * 100).toPrecision(3)}%`} />
                  : <CircularProgressbar styles={buildStyles(circleStyle)} value={0} text="0%" />}
              </IonCol>
              <IonCol>
                  {/* {console.log('selectedVal: ', selectedVal.current.label, selectedVal.current.value)} */}
                <IonNote>{traits[selectedVal.current.label as keyof AnalyticsSelectTypes]}</IonNote>
              </IonCol>
            </IonRow>
          </IonGrid>
        <IonCardContent>
        <strong>{`${selectedVal.current?.label ?? 'Select Option'}`}</strong>
          <IonSelect placeholder={`${selectedVal.current?.label ?? 'Select'}`} interface="action-sheet" onIonChange={handleChange}>
            {Object.keys(selectOptions).map((k, i) => {
              return (
                <IonSelectOption key={i} value={{label: k, value: selectOptions[k as keyof AnalyticsSelectTypes]}} >
                  {k}                                                             
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonCardContent>
      </IonCard>
    </Fragment>
  );
}

export default AnalyticsCard;