import { useQuery } from 'react-query';
import { IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardTitle } from '@ionic/react';
import PercentBar from './PercentBar';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { urls } from '../utilities/urls';

interface AnalyticsTrendTypes {
  days: '7' | '14' | '30' | '60' | '120' | '240' | '365'
}

interface TrendTypes {
  [key: string]: number
  membersCount: number
}

interface ObjectTrendTypes {
  interestTrends: TrendTypes
  demographicsTrends: TrendTypes
  fullInterests: TrendTypes
  fullDemographics: TrendTypes
}

const AnalyticsTrend = ({ days }: AnalyticsTrendTypes) => {

  const api = useApiContext();
  const app = useAuthContext();

  const { data, error, isSuccess, isError, isLoading, isFetching} = useQuery<ObjectTrendTypes>('trends-data', () => {
    return api.getTrends(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 'trends', days, app.orgcode, app.userDetails?.email ?? '', app.jwtRef.current)
      .then(res => res).catch(err => { throw new Error(err) });
  }, {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: 2
  });

  if(isLoading || isFetching) return <h4>Getting your trends!</h4>

  if(isError) return <h4>There was an error getting your trends. Please try again later.</h4>

  if(isSuccess && data && (data.interestTrends && data.demographicsTrends)) return (
    <IonGrid>
      <h3 style={{marginLeft: '0.9rem'}}>Trends in the last {days} days</h3>
      <IonRow>
        <IonCol sizeLg="6" sizeMd="6" sizeSm="12">
          <IonCard>
            <IonCardContent>
              <IonCardTitle>Trending Interests</IonCardTitle>
              <IonCardContent >
                { data.interestTrends.membersCount > 0 ?
                    Object.entries(data?.interestTrends as TrendTypes).filter((([k, v]) => k !== 'membersCount')).map(([key, value], index) => {
                      return <PercentBar key={index} title={key} hexNumber={value} denominator={data.interestTrends.membersCount} />
                    })
                    :
                    <h3>No new assessments in the past {days} days.</h3>
                }
              </IonCardContent>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol sizeLg="6" sizeMd="6" sizeSm="12">
        <IonCard>
            <IonCardContent>
              <IonCardTitle>Full Interests</IonCardTitle>
              <IonCardContent>
                {Object.entries(data?.fullInterests as TrendTypes ?? {}).filter((([k, v]) => k !== 'membersCount')).map(([key, value], index) => {
                    return <PercentBar key={index} title={key} hexNumber={value} denominator={data.fullInterests.membersCount} />
                  })
                }
              </IonCardContent>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeLg="6" sizeMd="6" sizeSm="12">
          <IonCard>
            <IonCardContent>
              <IonCardTitle>Trending Demographics</IonCardTitle>
              <IonCardContent >
                { data.demographicsTrends.membersCount > 0 ?
                    Object.entries(data?.demographicsTrends as TrendTypes ?? {}).filter((([k, v]) => k !== 'membersCount')).map(([key, value], index) => {
                      return <PercentBar key={index} title={key} hexNumber={value} denominator={data.demographicsTrends.membersCount} />
                    })
                    :
                    <h3>No new assessments in the past {days} days.</h3>
                }
              </IonCardContent>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol sizeLg="6" sizeMd="6" sizeSm="12">
        <IonCard>
            <IonCardContent>
              <IonCardTitle>Full Demographics</IonCardTitle>
              <IonCardContent>
                {Object.entries(data?.fullDemographics as TrendTypes ?? {}).filter((([k, v]) => k !== 'membersCount')).map(([key, value], index) => {
                    return <PercentBar key={index} title={key} hexNumber={value} denominator={data.fullDemographics.membersCount} />
                  })
                }
              </IonCardContent>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  else return <h4>Unforuntately, an error occurred when getting your trends. Please try again later.</h4>
}

export default AnalyticsTrend;