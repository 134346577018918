import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import Avatar from 'react-avatar';
import { userName, capitalizeText } from '../style/ts/SidesheetContentCSS';
import { chosenUserTypes } from './Sidesheet';
import { CurrentUserTypes } from './MemberMatches';
import { UserAttributeTypes } from '../AuthProvider';
import '../style/ionic/SidesheetContent.css';

export interface UsersContentTypes {
  chosen: chosenUserTypes
  user: CurrentUserTypes
  admin: UserAttributeTypes | undefined
  connectionStrength: number
  toggleSidesheet: (ssOptions: chosenUserTypes, user: CurrentUserTypes, connectionStrength: number) => void
}

const SidesheetContent = ({ toggleSidesheet, chosen, user, connectionStrength }: Omit<UsersContentTypes, 'admin'>) => {
  const { user2Id, fname, lname, email2, score, handleConnection, orgcode } = chosen;
  const { user1Id, name, last, email } = user;

  const closeSideSheet = () => {
    chosen.show = false;
    toggleSidesheet(chosen, user, connectionStrength);
  }

  const createConnection = () => {
    handleConnection && handleConnection({ user2Id, fname, lname, email2, score, handleConnection, orgcode }, { user1Id, name, last, email }, connectionStrength);
  }

  return (
    <IonGrid>
      <IonRow style={{ marginTop: "15rem" }} className="ion-align-items-center">
        <IonCol pushXl="2" sizeXl="4">
          <Avatar name={`${fname} ${lname}`} size="80" round />
        </IonCol>
        <IonCol sizeXl="5">
          <h4 style={userName}>{fname} {lname}</h4>
        </IonCol>
      </IonRow>
      <IonRow className="ion-align-items-center">
        <IonCol>
          <h4>{score}% Match Strength with <i style={capitalizeText}>{name}</i></h4>
        </IonCol>
      </IonRow>
      <IonRow className="ion-align-items-center">
        <IonCol>
          <IonButton onClick={createConnection}>+ Make Connection</IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton fill="clear" color="dark" onClick={closeSideSheet}>Close</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default SidesheetContent;