import { IonNote, IonLoading, IonCard, IonCardHeader, IonButton, IonIcon } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import Avatar from 'react-avatar';
import ConnectionSidesheet, { ConnectionSideSheetHandle } from './ConnectionSideSheet';
import { useQuery } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { useLocation, useHistory } from 'react-router';
import { urls } from '../utilities/urls';
import { capitalize } from '../utilities/text';
import { CurrentUserTypes } from './MemberMatches';
import { MemberTypes, StringTypes } from './Members';
import { DynamoNumberTypes } from './Member';
import { ionCardContainer, cardName } from '../style/ts/MatchComponentCSS'
import { sectionContainer, pageTitle, subContainer } from '../style/ts/MemberMatchesCSS';
import { useRef, useState } from 'react';

interface SingleMembersTypes {
  id: StringTypes
  orgcode: StringTypes
}

interface SingleMembersMapTypes {
  M: SingleMembersTypes
}

interface MembersTypes {
  member1: SingleMembersMapTypes
  member2: SingleMembersMapTypes
}

export interface MembersMapTypes {
  M: MembersTypes
}

interface ListType {
  L: string[]
}

interface ConnectionTypes {
  id: StringTypes
  matchDateTime: StringTypes
  members: MembersMapTypes
  meetingDates: ListType
  connectionStrength: DynamoNumberTypes
  type: StringTypes
}

export interface MemberConnectionTypes {
  connectionItems: ConnectionTypes
  connectionDetails: MemberTypes
}

interface ConnectionErrorTypes {
  message: string
}

const MemberConnections = () => {
  const userAuth = useAuthContext();
  const api = useApiContext();
  const history = useHistory();
  const { state: { user1Id, name, cIds } = {} } = useLocation<CurrentUserTypes>();
  const [currentConnectionData, setCurrentConnectionData] = useState({} as MemberConnectionTypes);
  const sidesheetRef = useRef<ConnectionSideSheetHandle | null>(null);

  const { data, isError, isLoading, isFetching, isSuccess } = useQuery<MemberConnectionTypes[] | ConnectionErrorTypes, Error>('connections', async () => {
    return await api.getConnections(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 'connections', user1Id, cIds, userAuth.jwtRef.current)
      .then(res => {
        return res;
      }).catch(err => { throw new Error(err) });
  }, {
    enabled: cIds?.length! >= 1,
    refetchOnWindowFocus: false,
    retry: 2
  })

  const showSidesheet = (connectionData: MemberConnectionTypes) => {
    setCurrentConnectionData(connectionData);
    sidesheetRef.current?.handleShow();
  }

  if (isError) return <IonNote>There seems to be a problem. Please try again later.</IonNote>;

  if (isLoading || isFetching) return <IonLoading isOpen />

  if (isSuccess && !(data as ConnectionErrorTypes)?.message && (data as []).length > 0) return (
    <section>
      <IonButton onClick={() => history.goBack()} fill="clear">
        <IonIcon slot="icon-only" icon={chevronBackOutline} />
      </IonButton>
      <ConnectionSidesheet ref={sidesheetRef} connectionData={currentConnectionData} />
      <aside style={sectionContainer}>
        <h3 style={pageTitle}>{capitalize(name)}'s Current Connections</h3>
        <IonNote>You may not see all connections for this member because they could have connections in other dashboards.</IonNote>
      </aside>
      <aside style={subContainer}>
        {((data as MemberConnectionTypes[])).map((u, index) => {
          return (
            <IonCard onClick={() => showSidesheet(u)} style={ionCardContainer} key={index} button >
              <IonCardHeader>
                <Avatar value={`${u.connectionItems.connectionStrength.N}%`} size="80" round={true} />
                <span style={cardName}>{u.connectionDetails.user.M.fname.S} {u.connectionDetails.user.M.lname.S} </span>
                <span>({u.connectionItems.type.S})</span>
                <h5>When: {new Date((u.connectionItems.matchDateTime.S as string).toLocaleUpperCase()).toLocaleDateString()}</h5>
              </IonCardHeader>
            </IonCard>
          );
        })}
      </aside>
    </section>
  )
  else return (
    <aside className="container">
      <IonButton onClick={() => history.goBack()} fill="clear">
        <IonIcon icon={chevronBackOutline} />
        Go Back
      </IonButton>
      <aside style={{ padding: "1rem" }}>
        {(data as ConnectionErrorTypes)?.message ? <h3>There seems to be a problem. Please try again later.</h3>
          : <h3>There are currently no connections for {capitalize(name)}.</h3>}
        <IonNote>You may not see all connections for this member because they could have connections in other dashboards.</IonNote>
      </aside>
    </aside>
  )
}

export default MemberConnections;