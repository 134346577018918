import { IonCol, IonNote, IonRow, IonSpinner } from '@ionic/react';
import { Fragment } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from '../../../hooks/apiContext';
import { useAuthContext } from '../../../hooks/authContext';
import { urls } from '../../../utilities/urls';
import { ProductTypes } from '../../interfaces/stripe/product-types';

// subscription details are already gathered in PaymentEditing
// subscriptionType is product number...use this to get product data
interface SubscriptionDetailsTypes {
  productData?: ProductTypes
  price: number
}

const SubscriptionDetails = ({productData, price}: SubscriptionDetailsTypes) => {

  const api = useApiContext();
  const auth = useAuthContext();

  const { data, isLoading, isFetching, isError } = useQuery('sub-data', async () => {
    try {
      return await api.getData(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', '', auth.orgcode ?? '', 'table-count', '', auth.userDetails?.email ?? '', auth.jwtRef.current);
    } catch (error) {
      throw error;
    }
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  })

  if(isLoading || isFetching) return (
    <IonSpinner />
  )

  else if(isError) return (
    <aside>An error has occurred. Please try again later.</aside>
  )

  else return (
    <Fragment>
      <IonRow>
        <IonCol sizeXl="3">
          <span><strong>Users:</strong> {(data.Items?.length > 0 && data?.Items[0]?.assessments?.N) ? data?.Items[0]?.assessments?.N : '0'}/{productData?.metadata?.maxUsers ?? 'Unlimited'}</span>
          {
            // (data?.Items[0]?.assessments?.N < 1) &&
            ((data?.Items[0]?.assessments?.N >= productData?.metadata?.maxUsers! ?? 0 - 9) && productData?.name !== 'Enterprise') &&
              <IonNote><span aria-label="warning" role="img">⚠️</span> Please note that you are getting close to your limit of {productData?.metadata?.maxUsers ?? '??'} members. If you surpass the limit, you will be automatically upgraded and charged.</IonNote>
          }
        </IonCol>
        <IonCol sizeXl="2">
          <span><strong>Plan:</strong> {productData?.name ?? 'No plan'}</span>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <span><strong>Payment:</strong> ${price}/month</span>
        </IonCol>
      </IonRow>
    </Fragment>
  );
}

export default SubscriptionDetails;