import { useSwiper } from "swiper/react";

const SwipeSlide = () => {
  const swiper = useSwiper();
  return (
    <aside style={{textAlign: "center"}}>
      <aside onClick={() => swiper.slideTo(0)} className="arrow left"></aside>
      <span>&nbsp;Swipe/Press Arrows to change segments&nbsp;</span>
      <aside onClick={() => swiper.slideTo(1)} className="arrow right"></aside>
    </aside>
  );
}

export default SwipeSlide;