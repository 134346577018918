import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import queryString, { ParsedUrlQuery } from 'querystring';
import { useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

interface QueryParamsTypes extends ParsedUrlQuery {
  qs: string
}

const OptOut = () => {
  const history = useHistory();
  const { search } = useLocation();
  const qparams = queryString.parse(search.substring(1)) as QueryParamsTypes;

  useLayoutEffect(() => {
    if(!qparams.qs) history.push('/');
  });

  return (
    <section>
      <p>If you wish to no longer recieve match or marketing emails from Rivet please confirm your email address below: </p>
      <IonList>
        <IonItem>
          <IonLabel>Email:</IonLabel>
          <IonInput name="email" type="text" placeholder="Email Address" />
        </IonItem>
      </IonList>
      <IonButton>Unsubscribe</IonButton>
      {/* <Link to="/Privacy">See our privacy policy for more details</Link> */}
    </section>
  );
}

export default OptOut;