import { MutableRefObject } from 'react';
import { IonButton, useIonModal } from '@ionic/react';
import FilterBody, { FilterTypes } from './FilterBody';
import { useAuthContext } from '../../hooks/authContext';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';

interface FilterCompTypes {
  filterChoices: MutableRefObject<FilterTypes>
  handleFiltration: () => void
}

const Filter = ({ filterChoices, handleFiltration }: FilterCompTypes) => {

  const app = useAuthContext();

  const handleDismiss = (data: any, role: string) => {
    dismiss(data, role);
  }

  const [present, dismiss] = useIonModal(FilterBody, {
    dismissModal: handleDismiss,
    filterChoicesRef: filterChoices,
    handleFiltration,
    sessionStoreKey: 'filterState',
    app
  });

  return (
    <IonButton mode="ios" onClick={() => present({
      cssClass: "no-bg-ion-card",
      onDidDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        // console.log('ev: ', ev.detail);
        // if(ev.detail.role === 'cancel') {
        //   filterChoices.current = {
        //     archetype: [],
        //     relationship: '',
        //     interests: [],
        //     topInterests: [],
        //     city_current: '',
        //     gender: '',
        //     ethnicity: '',
        //     user: {} as FilterAddedOptionsTypes,
        //     customAnswers: []
        //   } as FilterTypes;
        // }
      }
    })}>Filter</IonButton>
  );
}

export default Filter;