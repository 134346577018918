import { useState } from 'react';
import { IonButton, IonLabel, IonSelect, IonSelectOption, SelectChangeEventDetail, useIonToast } from '@ionic/react';
import { getAutoSettings, saveAutoSettings } from '../../utilities/localSave';
import { AutoMatchTypes } from './AutoMatchSettings';
import '../../style/ionic/AutoConnectSettings.css';

interface AutoConnectSettingsTypes {
  dismissModal: () => void
}

// This is the body, which will go into the useIonModal
const AutoConnectSettings = ({dismissModal}: AutoConnectSettingsTypes) => {

  const [presentToast] = useIonToast()

  const [settings, setSettings] = useState<AutoMatchTypes>(getAutoSettings() ? getAutoSettings() as AutoMatchTypes : {connections: 1, strength: 'Excellent', matches: 10, connectionsLimit: 3, doNotMatchSameAnswer: false});
  
  const handleSelectChange = (tag: string, val: number) => {
    console.log('change value: ', val);
    setSettings(s => ({...s, [tag as keyof AutoMatchTypes]: val}));
  }

  const handleSubmit = () => {
    saveAutoSettings(settings);
    dismissModal();
    presentToast({
      header: 'Settings saved!',
      message: 'You may continue to auto connect your segment(s).',
      duration: 2400,
      position: 'bottom'
    })
  }

  return (
    <section className="auto-connect-container">
      <IonLabel>Number of connections per member:</IonLabel>
      <IonSelect style={{width: '4rem'}} value={settings.connections} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('connections', e.detail.value)}>
        {Array(3).fill(0).map((_, index) => <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>)}
      </IonSelect>
      <IonLabel>Number of matches per member:</IonLabel>
      <IonSelect style={{width: '4rem'}} value={settings.matches} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('matches', e.detail.value)}>
        {Array(10).fill(0).map((_, index) => <IonSelectOption key={index} value={(index + 1) * 10}>{(index + 1) * 1}</IonSelectOption>)}
      </IonSelect>
      <IonLabel>Connection strength limit:</IonLabel>
      <IonSelect style={{width: '9rem'}} value={settings.strength} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('strength', e.detail.value)}>
        {['Excellent', 'Great', 'Okay', 'Low'].map((s, index) => <IonSelectOption key={index} value={s}>{s}</IonSelectOption>)}
      </IonSelect>
      <IonLabel>If matched member has:</IonLabel>
      <IonSelect style={{display: 'inline-flex'}} value={settings.connectionsLimit} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('connectionsLimit', e.detail.value)}>
        {Array(10).fill(0).map((_, index) => <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>)}
      </IonSelect>
      <IonLabel>active connection(s), then do not connect</IonLabel>
      <hr />
      <IonButton mode="ios" color="dark" size="small"  onClick={dismissModal}>Cancel</IonButton>
      <IonButton mode="ios" size="small"  onClick={handleSubmit}>Save</IonButton>
    {/* Add loading modal with explanation */}
    </section>
  );
}

export default AutoConnectSettings;