import { CSSProperties } from 'react';

export const userName: CSSProperties = {
  paddingLeft: '1rem',
  fontSize: '1rem',
  display: 'inline',
  textTransform: 'uppercase'
}

export const subContainer: CSSProperties = {
  margin: '2rem'
}

export const capitalizeText: CSSProperties = {
  textTransform: 'capitalize'
}