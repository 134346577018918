import { SettingsTypes } from '../AuthProvider';
import { AutoMatchTypes } from '../components/Enterprise/AutoMatchSettings';

export const saveSettings = (obj: SettingsTypes) => {
  localStorage.setItem('currentSettings', JSON.stringify(obj));
}

export const getSettings = (): SettingsTypes | undefined => {
  const settings = localStorage.getItem('currentSettings');
  return typeof settings === 'string' ? JSON.parse(settings) : undefined;
}

export const saveAutoSettings = (obj: AutoMatchTypes) => {
  localStorage.setItem('currentAutoSettings', JSON.stringify(obj));
}

export const getAutoSettings = (): AutoMatchTypes | undefined => {
  const settings = localStorage.getItem('currentAutoSettings');
  return typeof settings === 'string' ? JSON.parse(settings) : {connections: 1, strength: 'Okay', matches: 50, connectionsLimit: 1, doNotMatchSameAnswer: false};
}

export const clearSettings = () => {
  localStorage.removeItem('currentSettings');
}