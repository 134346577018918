import { IonGrid, IonRow, IonCol, IonButton, IonTitle } from '@ionic/react';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
import randomInt from '../utilities/random-int';
import { MemberReturnTypes, MemberTypes } from './Members';
import './Table.css';

export interface TableTypes {
  membersLists: MemberReturnTypes[] | undefined
}

const Table = ({membersLists}: TableTypes) => {
  const history = useHistory();
  const sendToMember = (id: string | undefined, alreadyViewed: boolean, email: string | undefined) => {
    history.push({
      pathname: `/Member/${id}`,
      state: {alreadyViewed, email: email}
    });
  }
  return (
      <IonGrid>
        {/* 4 columns */}
        <IonRow style={{padding: "1rem 0"}}>
          <IonCol sizeXl="4" pushXl="1"><strong>Member</strong></IonCol>
          <IonCol sizeXl="2"><strong>Last Assessment</strong></IonCol>
          <IonCol sizeXl="3" pushXl="1"><strong>Connections</strong></IonCol>
          <IonCol sizeXl="3"><strong>Archetype</strong></IonCol>
        </IonRow>
        {(membersLists && membersLists?.length > 0) ?
          membersLists?.map(memberList => {
            // if(memberList?.Items?.length === 0) return (
            //   <IonRow key={`${randomInt()}`} className="row-background" style={{borderTop: "1px solid lightgray"}}>
            //     <IonCol>
            //       <h3 style={{marginTop: "0.5rem"}}>There are currently no members...</h3>
            //     </IonCol>
            //   </IonRow>
            // );
            return (
              memberList?.Items?.map(member => {
                return (
                  <IonRow className="row-background" style={{borderTop: "1px solid lightgray"}} key={member.id!.S} onClick={() => sendToMember(member.id!.S, member.alreadyViewed?.BOOL ?? false, member.user.M.email.S)}>
                    <IonCol sizeXl="1">
                      {/* {(member.alreadyViewed && !member.alreadyViewed.BOOL) && <span className="badge satoshi">New!</span>} */}
                      <Avatar className={`${(member.alreadyViewed && !member.alreadyViewed.BOOL) && 'shiny'}`} name={`${member.user.M.fname.S} ${member.user.M.lname.S}`} size="60" round={true} />
                    </IonCol>
                    <IonCol sizeXl="3">
                      <h4 style={{textTransform: "capitalize"}}>{member.user.M.fname.S} {member.user.M.lname.S}</h4>
                    </IonCol>
                    <IonCol sizeXl="2">
                      <h4>{new Date(member.latestAssessment.S.toLocaleUpperCase() ?? '').toLocaleDateString()}</h4>
                    </IonCol>
                    <IonCol className="ion-text-center" sizeXl="3">
                      <h4>{member.activeConnections?.N}</h4>
                    </IonCol>
                    <IonCol sizeXl="3">
                      {/* <IonButton fill="clear" className="col-button" onClick={() => sendToMember(member.id!.S, member.alreadyViewed?.BOOL ?? false, member.user.M.email.S)}>View Profile</IonButton> */}
                      <h4 style={{textTransform: "capitalize"}}>{member.archetype.S}</h4>
                    </IonCol>
                  </IonRow>
                )
              })
            );
          }) : 
          <IonRow>
            <IonCol>
              <h3 style={{marginTop: "0.5rem"}}>There are currently no members.</h3>
            </IonCol>
          </IonRow>
        }
      </IonGrid>
  );
}

export default Table;