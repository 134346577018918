import { IonCol, IonGrid, IonRow, IonLabel, IonInput, IonButton, useIonPicker } from '@ionic/react';
import { useRef, useState } from 'react';
import valid from 'card-validator';
import '../../../style/ionic/Subscription.css';
import { useApiContext } from '../../../hooks/apiContext';
import { urls } from '../../../utilities/urls';

interface NewPaymentMethodTypes {
  dismiss: (data: any, role: string) => void
  jwtRef: string
  customerId: string
}

interface CardRefTypes {
  name: string
  ccn: string
  zip: string
  cvv: string,
  exMonth: string,
  exYear: string
}

const NewPaymentMethod = ({dismiss, jwtRef, customerId}: NewPaymentMethodTypes) => {

  const api = useApiContext();
  const cardRef = useRef({} as CardRefTypes);
  const cardSectionRef = useRef<null | HTMLIonInputElement>(null);
  const [presentPicker] = useIonPicker();
  const [chosenMM, setChosenMM] = useState('');
  const [chosenYY, setChosenYY] = useState('');
  const pickerOptions = (min: number, max: number) => [...Array(max-min)].map((_,i) => i+min+1).map((num) => {
    return (num.toString().length > 1) ? {text: `${num}`, value: `${num}`} : {text: `${0}${num}`, value: `${0}${num}`};
  });

  const openPicker = async (isMonth: boolean) => {
    presentPicker({
      columns: [
        {
          name: 'expiration',
          options: isMonth ? pickerOptions(0, 12) : pickerOptions(22, 99),
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (value) => {
            // window.alert(`You selected: ${value.languages.value}`);
            isMonth ? setChosenMM(value.expiration.value) : setChosenYY(value.expiration.value);
          },
        },
      ],
    })
  }

  const validatePaymentMethod = (num: string, name: string, exMonth: string, exYear: string, cvv: string, zip: string) => {
    const cardObj = {...valid.number(num, {maxLength: 16}), description: 'card number'};
    const ccname = {...valid.cardholderName(name), description: 'cardholder name'};
    const xm = {...valid.expirationMonth(exMonth), description: 'expiration month'};
    const xy = {...valid.expirationYear(exYear), description: 'expiration year'};
    const security = {...valid.cvv(cvv, cardObj.card?.type === 'american express' ? 4 : 3), description: 'security code'};
    const postal = {...valid.postalCode(zip), description: 'zip code'};

    const validateArray = [cardObj, ccname, xm, xy, security, postal];
    const correctionArray = validateArray.filter(v => !v.isValid);
    const validity = correctionArray.length > 0;
    if(!validity) return true;
    else {
      const invalidInputs = correctionArray.map(v => v.description).join(', ');
      alert(`The following inputs need to be fixed: ${invalidInputs}`);
      return false;
    }
  }

  const submitPaymentMethod = async () => {
    // add new payment method via api
    const inputs = cardSectionRef.current?.querySelectorAll('input');
    const inputsArray = inputs ? Array.from(inputs) : undefined;
    cardRef.current = inputsArray?.reduce((p, c) => {
      return { ...p, [`${c.name}`]: c.value };
    }, {exMonth: chosenMM, exYear: chosenYY}) as CardRefTypes;
    
    const isValid = validatePaymentMethod(cardRef.current.ccn, cardRef.current.name, chosenMM, chosenYY, cardRef.current.cvv, cardRef.current.zip);
    if(!isValid) return;

    try {
      // if valid then run new payment method
      const paymentMethod = await api.addPaymentMethod(process.env.NODE_ENV !== 'production' ? urls.payment : '/v1/payment-control', customerId, 'paymentMethod.create', cardRef.current, jwtRef).then(res => res).catch(err => { throw err });
      if(!paymentMethod.error) {
        alert('Payment method has been succesfully added! It is now your default payment method.');
      }
      dismiss('', 'accept');
    } catch (error) {
      alert(`An error occurred: ${error}`);
    }
  }

  return (
    <section ref={cardSectionRef} className="modal-container">
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonLabel>Cardholder Name</IonLabel>
            <IonInput className="payments" type="text" name="name" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel>Card Number</IonLabel>
            <IonInput className="payments" type="text" minlength={16} maxlength={16}  name="ccn" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton className="payments" size="small" onClick={() => openPicker(true)} >{chosenMM ? chosenMM : 'Exp. Month'}</IonButton>
            <IonButton className="payments" size="small" onClick={() => openPicker(false)} >{chosenYY ? chosenYY : 'Exp. Year'}</IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel>Security Code</IonLabel>
            <IonInput className="payments" type="text" minlength={3} maxlength={4}  name="cvv" />
          </IonCol>
          <IonCol>
            <IonLabel>Postal Code</IonLabel>
            <IonInput className="payments" type="text" minlength={5} maxlength={5}  name="zip" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton fill="clear" color="dark" onClick={() => dismiss('', 'cancel')}>Cancel</IonButton>
            <IonButton onClick={submitPaymentMethod}>Add</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </section>
  );
}

export default NewPaymentMethod;