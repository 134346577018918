import { MouseEvent } from 'react';
import { IonChip, IonButton, IonIcon, IonLabel } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

interface FilterChipTypes {
  chipText: string
  handleRemoveChip: (chipValue: string | undefined) => void
}

const FilterChip = ({ chipText, handleRemoveChip }: FilterChipTypes) => {

  const removeChip = (chipValue: string | undefined) => {
    handleRemoveChip(chipValue);
  }

  return (
    <IonChip title={chipText} onClick={(e: MouseEvent<HTMLIonChipElement>) => removeChip(e.currentTarget.title)}>
      <IonLabel>{chipText}</IonLabel>
      <IonIcon icon={closeCircleOutline}></IonIcon>
    </IonChip>
  );
}

export default FilterChip;