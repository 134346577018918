import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonNote, IonRow, IonSpinner, useIonModal, useIonToast } from '@ionic/react';
import { useQuery, useQueryClient } from 'react-query';
import { useApiContext } from '../../../hooks/apiContext';
import { useAuthContext } from '../../../hooks/authContext';
import { urls } from '../../../utilities/urls';
import { addSharp, removeCircleSharp } from 'ionicons/icons';
import { CustomerTypes } from '../../interfaces/stripe/customer-types';
import { SubscriptionTypes } from '../../interfaces/stripe/subscription-types';
import AccountModal from './AccountModal';
import NewPaymentMethod from './NewPaymentMethod';
import SubscriptionModal from './SubscriptionModal';
import { PaymentMethodTypes } from '../../interfaces/stripe/paymentMethod-types';
import randomInt from '../../../utilities/random-int';
import RemovePaymentModal from './RemovePaymentModal';
import { useRef } from 'react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import SubscriptionDetails from './SubscriptionDetails';
import { ProductTypes } from '../../interfaces/stripe/product-types';
import { capitalize } from '../../../utilities/text';
import '../../../style/ionic/PaymentEditing.css';
import { useHistory } from 'react-router';

interface PaymentEditingTypes {
  customer: CustomerTypes
  subscription: SubscriptionTypes
  paymentMethods: PaymentMethodTypes
  product: ProductTypes
  price: number
}

interface ErrorObjectTypes {

}

// interface AccountErrorTypes {
//   value: 
// }

const PaymentEditing = () => {

  const currentpmId = useRef('');
  const toSubcribe = useRef(false);
  const auth = useAuthContext();
  const api = useApiContext();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [presentToast] = useIonToast();
  // const prices = [{name: 'Teams', price: '$99'}, {name: 'Departments', price: '$199'}, {name: 'Enterprise', price: '$399'}];
  const prices = [{name: 'Teams', price: '$99'}, {name: 'Departments', price: '$199'}];

  const priceIdRef = useRef('');

  const { data, isLoading, error, isError, isFetching } = useQuery<PaymentEditingTypes>('payment-account-data', async () => {
    return await api.getAccount(process.env.NODE_ENV !== 'production' ? urls.payment : '/v1/payment-control', auth.userDetails?.['custom:customerId'], 'customer.retrieve', auth.jwtRef.current).then(res => res).catch(err => { throw err });
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  })

  const subDismiss = (event: CustomEvent<OverlayEventDetail<any>>) => {
    if(event.detail.role === 'OK') {
      presentToast({
        message: `You've been successfully ${!toSubcribe.current ? `set to be unsubscribed. You will be downgraded on: ${(new Date(data?.subscription.current_period_end! * 1000) ?? 0).toLocaleDateString()}` : 'subscribed'}.`,
        duration: 4000,
        position: 'middle',
        onDidDismiss: () => {
          queryClient.resetQueries('payment-account-data');
        }
      });
    }
  }

  const [subscriptionPresent, subscriptionDismiss] = useIonModal(SubscriptionModal, {
    dismiss: (data: any, role: string) => subscriptionDismiss(data, role),
    toSubcribe: toSubcribe.current, 
    subId: data?.subscription?.id as string,
    cusId: data?.customer.id as string,
    priceId: priceIdRef.current, 
    paymentMethodId: data?.customer.invoice_settings.default_payment_method,
    auth
  })

  const subscriptionModalAction = (subscriptionChoice: string, isCreate: boolean) => {
    priceIdRef.current = subscriptionChoice;
    toSubcribe.current = isCreate
    subscriptionPresent({
      onDidDismiss: subDismiss
    })
  }

  const [accountPresent, accountDismiss] = useIonModal(AccountModal, {
    dismiss: () => accountDismiss(),
    cusId: data?.customer.id,
    auth
  })

  const [paymentMethodPresent, paymentMethodDismiss] = useIonModal(NewPaymentMethod, {
    dismiss: (data: any, role: string) => paymentMethodDismiss(data, role),
    jwtRef: auth.jwtRef.current,
    customerId: data?.customer.id
  })

  const [removePaymentPresent, removePaymentMethodDismiss] = useIonModal(RemovePaymentModal, {
    dismiss: (data: string) => removePaymentMethodDismiss(data),
    methodsOverOne: data && data?.paymentMethods.data.length > 1
  })

  const removePaymentMethod = (pmId: string) => {
    currentpmId.current = pmId;
    removePaymentPresent({
      onDidDismiss: async (ev: CustomEvent<OverlayEventDetail>) => {
        if(ev.detail.data === 'confirm') {
          try {
            const paymentMethod = await api.removePaymentMethod(process.env.NODE_ENV !== 'production' ? urls.payment : '/v1/payment-control', currentpmId.current, data?.customer.id, 'paymentMethod.delete', auth.jwtRef.current).then(res => res).catch(err => { throw err });
            console.log('paymentMethod: ', paymentMethod);
            if(!paymentMethod.error) {
              alert('Payment method removed.');
              queryClient.resetQueries('payment-account-data');
            }
          } catch (error) {
            alert(`An error occurred: ${error}`);
          }
        }
      }
    });
  }

  if(isLoading || isFetching) return (
    <aside>
      <IonSpinner name="circular" ></IonSpinner>
    </aside>
  ) 
  else if(isError) return (error as any).error.type === 'StripeInvalidRequestError' ?
        <aside>
          <p>
            You have been grandfathered into Rivet, and currently do not have a new type of account.
          </p>
          <p>
            We ask for your patience as we continue our transition from Like|Minded to Rivet. Thank you!
          </p>
        </aside>
        :
        <aside>
          An error occurred...please refresh or try later.
        </aside>
  
  else return (
    <section>
      <h1>Edit Account</h1>
      <IonGrid>
        <IonRow>
          <IonCol>
            <h3>Subscription</h3>
            <IonGrid>
              <SubscriptionDetails productData={data?.product} price={data?.price!} />
              <IonRow>
                <IonCol>
                  <p><strong>Status:</strong> {capitalize(data?.subscription.status)}</p>
                  <p><strong>Next payment:</strong> {data?.subscription.current_period_end !== 0 ? new Date(data?.subscription.current_period_end! * 1000).toLocaleDateString() : 'N/A'}</p>
                </IonCol>
              </IonRow>
            </IonGrid>
            <hr />
            <h3>Payment Method(s)</h3>
            {data && data?.paymentMethods.data.length > 0 &&
              data?.paymentMethods.data.map(pm => {
                return <IonItem key={randomInt()}>
                  Card ending in {pm.card.last4} &nbsp; <IonIcon icon={removeCircleSharp} style={{color: "red", cursor: "pointer"}} onClick={() => removePaymentMethod(pm.id)} />
                </IonItem>
              })
            }
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton fill="clear" color="dark" onClick={() => paymentMethodPresent({
              onDidDismiss: (event) => {
                if(event.detail.role === 'accept') queryClient.resetQueries('payment-account-data');
              }
            })}>
              <IonIcon slot="start" icon={addSharp}></IonIcon>
              Set New Payment Method
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h3 className="danger-zone">Danger Zone</h3>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {auth.tier === 'gold' ?
              !data?.subscription.cancel_at_period_end ? 
                <aside>
                  <h3>Unsubscribe</h3>
                  <IonNote>Stops monthly payments, but access remains without paid features.</IonNote>
                </aside>
                :
                <aside>
                  <h3>Set to be unsubscribed.</h3>
                </aside>
              :
              <aside>
                <h3>Subscribe</h3>
                <IonNote>Subscribes you to monthly payments, and grants access to paid features.</IonNote>
              </aside>
            }
            {auth.tier === 'gold' ?
              !data?.subscription.cancel_at_period_end ?
                <IonButton fill="outline" color="dark" onClick={() => {
                  toSubcribe.current = false;
                  subscriptionPresent({
                  onDidDismiss: subDismiss
                })}}>Unsubscribe</IonButton>
                :
                <aside>
                  <p>You can change to other plans as well:</p>
                  {prices.filter(p => p.name !== data.product.name).map(({name, price}, index: number) => {
                    return <IonButton key={`${index}-${randomInt()}`} fill="outline" color="dark" onClick={() => subscriptionModalAction(name.toLowerCase(), true)}>{name} | {price}/month</IonButton> 
                  })}
                  <IonNote>*This will cause your current subscription to be deleted immediately and be replaced by your new choice. <strong>NOTE: You will also be charged for the new subscription.</strong></IonNote>
                </aside>
              :
              <aside>
                {prices.map(({name, price}, index: number) => {
                  return <IonButton key={`${index}-${randomInt()}`} fill="clear" color="dark" onClick={() => subscriptionModalAction(name.toLowerCase(), true)}>{name} | {price}/month</IonButton> 
                })}
              </aside>
            }
          </IonCol>
          <IonCol>
            <h3>Delete Account</h3>
            <IonNote>Stops monthly payments, deletes your account, and removes your data.</IonNote>
            <IonButton fill="outline" color="dark" onClick={() => accountPresent()}>Delete Account</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </section>
  );
}

export default PaymentEditing;