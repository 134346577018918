import { useRef, useLayoutEffect, useEffect } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonLoading, IonRow } from '@ionic/react';
import { closeCircleSharp, filter, downloadSharp } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import Table from './Table';
import { HexacoTypes, DynamoStringTypes, DynamoNumberTypes, DynamoBoolTypes } from './Member';
import Search from './Search';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Parser } from 'json2csv';
import { urls } from '../utilities/urls';
import AutoMatch from './Enterprise/AutoMatch';
import Filter from './Enterprise/Filter';
import { FilterAddedOptionsTypes, FilterTypes } from './Enterprise/FilterBody';
import FilterChip from './FilterChip';
import randomInt from '../utilities/random-int';
import { QuestionAnswerTypes } from './interfaces/questions-types';

export interface StringTypes {
  S?: string
  SS?: string[]
}

export interface SingleListTypes {
  0: StringTypes
}

export interface ListTypes {
  L: SingleListTypes
}

export interface HexacoLevelMapTypes {
  conscientiousness: DynamoStringTypes
  honesty: DynamoStringTypes
  openness: DynamoStringTypes
  neuroticism: DynamoStringTypes
  extraversion: DynamoStringTypes
  agreeableness: DynamoStringTypes
}

export interface HexacoLevelTypes {
  M: HexacoLevelMapTypes
}

export interface UserMapNameTypes {
  fname: StringTypes
  lname: StringTypes
  email: StringTypes
  orgcode: ListTypes
  age: StringTypes
  dobmonth: StringTypes
  dobday: StringTypes
  dobyear: StringTypes
  buddy: StringTypes
  years: DynamoNumberTypes
  mentor: StringTypes
  department: StringTypes
  intern: StringTypes
}

export interface MapTypes {
  M: UserMapNameTypes
}

export interface MemberTypes {
  user: MapTypes
  id?: StringTypes
  orgcode?: ListTypes
  hexaco?: HexacoTypes
  hexacoLevels?: HexacoLevelTypes
  activeConnections?: DynamoNumberTypes
  latestAssessment: DynamoStringTypes
  alreadyViewed: DynamoBoolTypes
  archetype: DynamoStringTypes
}

export interface MemberReturnTypes {
  Items: MemberTypes[]
  NextToken?: StringTypes
}

const Members = () => {
  const api = useApiContext();
  const userAuth = useAuthContext();
  const history = useHistory();
  const useQuery = useQueryClient();
  const searchRef = useRef<string | undefined>('');
  const isFiltration = useRef<boolean>(false);
  const json2csvParser = useRef<Parser<any>>(new Parser());

  const filterDefaultState = {
    archetype: [],
    relationship: '',
    interests: [],
    topInterests: [],
    city_current: '',
    education: '',
    gender: '',
    ethnicity: '',
    user: {} as FilterAddedOptionsTypes,
    customAnswers: []
  } as FilterTypes

  const filterChoices = useRef<FilterTypes>(filterDefaultState);

  const handleChipRemoval = (selection: string, tag: string | undefined) => {
    switch (tag) {
      case 'archetype':
        filterChoices.current.archetype = [];
        break;
      case 'relationship':
      case 'gender':
      case 'ethnicity':
      case 'education':
        (filterChoices.current[tag as keyof FilterTypes] as string) = '';
        break;
      case 'relationship':
        filterChoices.current.relationship = '';
        break;
      case 'city_current':
        filterChoices.current.city_current = '';
        break;
      case 'interests':
        const chipIndex = filterChoices.current.interests.findIndex(i => i === selection);
        filterChoices.current.interests.splice(chipIndex, 1);
        break;
      case 'topInterests':
        const chipIndexTop = filterChoices.current.topInterests.findIndex(i => i === selection);
        filterChoices.current.interests.splice(chipIndexTop, 1);
        break;
      case 'user':
        delete filterChoices.current.user[selection as keyof FilterAddedOptionsTypes];
        break;
      case 'customAnswers':
        const chipIndexCustom = filterChoices.current.customAnswers.findIndex(i => Object.keys(i)[0] === selection);
        filterChoices.current.customAnswers.splice(chipIndexCustom, 1);
        break;
      default:
        break;
    }

    // if nothing is chosen, the full clear
    if(JSON.stringify(filterChoices.current) === JSON.stringify(filterDefaultState)) {
      filterChoices.current = filterDefaultState;
      sessionStorage.removeItem('filterState');
    } else sessionStorage.setItem('filterState', JSON.stringify(filterChoices.current));
    useQuery.refetchQueries(['membersData']);
  }

  const allPages = useRef<MemberTypes[]>([]);
  const isSearch = useRef(false);

  useLayoutEffect(() => {
    if(!userAuth.auth) history.push('/Home');
  }, []);

  const { isSuccess, fetchNextPage, isLoading, isError, error, data, isFetching, hasNextPage } = useInfiniteQuery<MemberReturnTypes, Error>('membersData', async ({pageParam = ''}) => {
    // console.log('getting ', pageParam, isSuccess, hasNextPage); // false true false may be causing probs
    // console.log('data: ', data);
    return await api.getData(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', pageParam ? pageParam : '', userAuth.orgcode ?? '', isFiltration.current ? 'filteredMembers' : 'members', isFiltration.current ? JSON.stringify(filterChoices.current) : searchRef.current, '', userAuth.jwtRef.current)
      .then((res: MemberReturnTypes) => res)
      .catch(err => { throw new Error(err) });
  }, {
    getNextPageParam: (lastPage) => lastPage.NextToken,
    enabled: (userAuth.orgcode !== undefined && !!userAuth.jwtRef.current),
    refetchOnWindowFocus: false,
    retry: 2
  });

  const clearSearchFilter = () => {
    isFiltration.current = false;
    isSearch.current = false;
    searchRef.current = '';
    filterChoices.current = filterDefaultState;
    sessionStorage.removeItem('filterState');
    useQuery.invalidateQueries(['membersData']);
    // refetch();
  }

  const handleSearch = () => {
    isFiltration.current = false;
    sessionStorage.removeItem('filterState');
    isSearch.current = true;
    useQuery.invalidateQueries(['membersData']);
    // refetch();
  }

  const handleFiltration = () => {
    isFiltration.current = true;
    isSearch.current = false;
    useQuery.invalidateQueries(['membersData']);
    // refetch();
  }

  const downloadBlob = (blob: Blob, filename: string) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.setAttribute('href', blobUrl);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    json2csvParser.current = new Parser();
  }

  const exportFiltered = async () => {
    await api.getData(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', '', userAuth.orgcode ?? '', 'export', JSON.stringify(filterChoices.current), '', userAuth.jwtRef.current)
      .then((res) => {
        const csv = json2csvParser.current.parse(res.Items);
        if(res.NextToken) console.log('next token found, create another button');
        const blob = new Blob([csv], { type: 'text/csv' });
        downloadBlob(blob, 'filteredResults.csv');
      }).catch(err => { console.error(err); alert('An error occurred, please try again later.') });
  }

  const nextPage = async () => {
    try {
      await fetchNextPage(!hasNextPage ? { pageParam: false} : undefined);
    } catch (err) {
      console.log(err);
    }
  }

  if (isLoading) return <IonLoading isOpen={true} />

  if (isError) return <aside>There was an error: {error?.message}</aside>

  if (isSuccess) return (
    <section style={{ padding: "2rem" }}>
      <Search refetch={handleSearch} searchParam={searchRef} />
      {userAuth.tier === 'gold' &&
        <IonGrid>
          <IonRow>
            <IonCol pushXl="1" sizeXl="4" sizeLg="12" sizeMd="12" sizeSm="12">
              <AutoMatch />
            </IonCol>
            <IonCol sizeXl="4">
              <Filter filterChoices={filterChoices} handleFiltration={handleFiltration} />
            </IonCol>
              { sessionStorage.getItem('filterState') &&
                <IonCol>
                  <IonButton size="small" color="dark" fill="outline" onClick={exportFiltered}>
                    <IonIcon icon={downloadSharp} /><span>&nbsp;Export</span>
                  </IonButton>
                  <IonButton size="small" color="dark" fill="outline" onClick={clearSearchFilter}>
                    <IonIcon icon={closeCircleSharp} /><span>&nbsp;Clear</span> 
                  </IonButton>
                </IonCol>
              }
          </IonRow>
          { sessionStorage.getItem('filterState') &&
            Object.entries(filterChoices.current).reduce((prev: JSX.Element[], [k, v], index) => {
              if(['interests', 'topInterests'].includes(k) && v) {
                const interests = v.map((i: string) => <span key={`${index}-${randomInt()}`}><FilterChip key={index} chipText={i} handleRemoveChip={() => handleChipRemoval(i, k)}  /></span>);
                prev = [...prev, ...interests];
              }
              else if(k === 'user') {
                const addedOptions = Object.entries(v).map(([ok, ov]: string[] | unknown[]) => <span key={`${index}-${randomInt()}`}><FilterChip key={index} chipText={ok as string} handleRemoveChip={() => handleChipRemoval(ok as string, k)}  /></span>);
                prev = [...prev, ...addedOptions];
              }
              else if(k === 'customAnswers') {
                const custom = v.map((i: QuestionAnswerTypes) => {
                  const [Qk] = Object.entries(i);
                  const txt = Qk.map((x, xInd) => (xInd === 0) ? '' : x).join(' ');
                  return <span key={`${index}-${randomInt()}`}><FilterChip key={index} chipText={txt} handleRemoveChip={() => handleChipRemoval(Qk[0], k)}  /></span>
                });
                prev = [...prev, ...custom];
              }
              else if(v | v.length && v.length > 0) {
                prev.push(
                  <span key={`${index}-${randomInt()}`}><FilterChip key={index} chipText={v} handleRemoveChip={() => handleChipRemoval(v, k)}  /></span>
                );
              }
              return prev;
            }, [])
          }
        </IonGrid>
      }
      <Table membersLists={data?.pages} />
      { hasNextPage && <IonButton color="dark" fill="clear" onClick={nextPage}>{isFetching ? 'Fetching...' : 'Load More'}</IonButton> }
    </section>
  );

  else return <aside>There was an error...please try again later.</aside>;
}

export default Members;