import { IonButton, IonIcon, IonNote, useIonModal } from '@ionic/react';
import { calendarClearSharp } from 'ionicons/icons';
import { Fragment, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { AuthContextTypes } from '../../AuthProvider';
import { useAuthContext } from '../../hooks/authContext';
import '../../style/ionic/AutoMatch.css';
import AutoMatchSettings from './AutoMatchSettings';

export interface AutoMatchBodyTypes {
  dismissModal: () => void
  dismissModalInvalidate: () => void
  auth: AuthContextTypes
  toSchedule: boolean
}

const AutoMatchModalBody = ({ dismissModal, dismissModalInvalidate, auth, toSchedule }: AutoMatchBodyTypes) => {
  return (
    <div className='ion-modal-container'>
      <h3>Connect all members</h3>
      <IonNote>You are about to connect all of your members based on the below settings.</IonNote>
      <AutoMatchSettings dismissModal={dismissModal} dismissModalInvalidate={dismissModalInvalidate} auth={auth} toSchedule={toSchedule} />
    </div>
  );
}

const AutoMatch = () => {
  const useQuery = useQueryClient();
  const auth = useAuthContext();
  const isSchedule = useRef(false);

  const dismissModalInvalidate = async () => {
    isSchedule.current = false;
    await useQuery.invalidateQueries(['membersData']);
    dismiss();
  }

  const handleDismiss = () => {
    isSchedule.current = false;
    dismiss();
  }

  const [present, dismiss] = useIonModal(AutoMatchModalBody, {
    dismissModal: handleDismiss,
    dismissModalInvalidate: dismissModalInvalidate,
    auth: auth,
    toSchedule: isSchedule.current
  })

  const presentScheduleModal = () => {
    console.log('present scheduler!');
    isSchedule.current = true;
    present({
      backdropDismiss: false
    });
  }

  return (
    <Fragment>
      <IonButton mode="ios" onClick={() => present({ backdropDismiss: false })}>Auto Connect</IonButton>
      <IonButton onClick={presentScheduleModal} color="secondary" title="Schedule Auto Connect"><IonIcon icon={calendarClearSharp} /></IonButton>
    </Fragment>
  );
}

export default AutoMatch;