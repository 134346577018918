import { IonButton, useIonAlert, useIonLoading } from '@ionic/react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useApiContext } from '../hooks/apiContext';
import { urls } from '../utilities/urls';
import '../style/ionic/RemoveMember.css';
import { useAuthContext } from '../hooks/authContext';

interface RemoveMemberTypes {
  id: string
  oc: string | undefined
  email: string | undefined
}

const RemoveMember = ({ id, oc, email }: RemoveMemberTypes) => {
  const api = useApiContext();
  const app = useAuthContext();
  const queryClient = useQueryClient();
  const [present] = useIonAlert();
  const [showLoading, dismissLoading] = useIonLoading();
  const history = useHistory();

  const backToMembersPage = async () => {
    await queryClient.resetQueries('membersData');
    history.push('/Members');
  }
  const mutation = useMutation(async () => {
    await api.removeUser(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', id, oc, email, app.jwtRef.current)
      .then(res => { dismissLoading(); return res; }).catch(err => { dismissLoading(); throw new Error(err); })
  }, 
  {
    onSuccess: () => {
      present({
        header: 'Member Removed',
        message: 'The member will no longer show up on your members list.',
        buttons: [
          { text: 'Ok', handler: () =>  { backToMembersPage() }},
        ],
        onDidDismiss: () => { backToMembersPage() }
      });
    },
    onError: () => {
      present({
        header: 'Removal Unsuccessful',
        message: 'It looks like there was an error. Please try again later.',
        cssClass: 'alert-unsuccessful'
      })
    }
  });

  const showAlert = () => {
    present({
      header: 'Remove Member?',
      message: 'Are you sure you want to remove this member? All of their connections will be removed as well.',
      cssClass: 'alert-unsuccessful',
      buttons: [
        'Cancel',
        { text: 'Yes', handler: () =>  { 
          showLoading({message: 'Loading...', spinner: 'dots'});
          if(oc && email) mutation.mutate();
          else alert('There is no organizational code available. Please refresh the page.');
        } },
      ]
    })
  }

  return (
    <>
      <IonButton fill='outline' size='small' color="dark" onClick={showAlert}>Remove Member</IonButton>
    </>
  );
}

export default RemoveMember;