import { CheckboxChangeEventDetail, IonAccordion, IonCheckbox, IonInput, IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react';
import { Fragment, MutableRefObject } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from '../../hooks/apiContext';
import randomInt from '../../utilities/random-int';
import { CustomQuestionTypes } from '../interfaces/questions-types';
import { IonCheckboxCustomEvent, IonInputCustomEvent } from '@ionic/core/dist/types/components';
import { FilterTypes } from './FilterBody';

interface CQComponentTypes {
  filterChoicesRef: MutableRefObject<FilterTypes>
  handleSelection: (selection: string, tag: string, interestName?: string) => void;
  oc: string | undefined
  jwtString: string | undefined
}

const CustomQuestions = ({ filterChoicesRef, handleSelection, oc, jwtString }: CQComponentTypes) => {
  
  const api = useApiContext();

  const { data, isSuccess, isError, error, isLoading, isFetching } = useQuery<CustomQuestionTypes | undefined>('custom-questions', async () => {
    return api.getCustomQuestions(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_QUESTIONS_URL! : '/v1/custom-questions', oc, jwtString);
  }, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!oc && !!jwtString,
  }) 

  if (isSuccess && data) return (
    <IonAccordion>
      <IonItem slot="header">
        <IonLabel>Custom Questions</IonLabel>
      </IonItem>
      <aside className="ion-padding" slot="content">
        {Object.entries(data.questions.M).map((q, index) => {
          return (
            <Fragment key={`${index}-${randomInt()}`}>
              <IonLabel>{q[1].M.q.S}</IonLabel>
              <IonItem>
                  { (q[1].M.type.S === 'choice' && q[1].M.list.L.length > 0)  ? 
                    <IonList>
                      {q[1].M.list.L.map((o, ind) => {
                        return <IonItem key={`${ind}-${randomInt()}`}>
                        <IonCheckbox checked={filterChoicesRef.current.customAnswers.some(c => {
                          if(Object.entries(c).some(j => typeof j[1] === 'string')) { } // pass
                          else if(Object.entries(c).some(d => typeof d[1] === 'object')) {
                            return Object.entries(c).some(e => e[1].includes(o.S));
                          }
                          return false}
                          )} slot="start" value={o.S.toLowerCase()} onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => handleSelection(`${index}-${e.detail.value}`, 'customAnswers', q[1].M.type.S)} />
                        <IonLabel>{o.S}</IonLabel>
                      </IonItem>
                      })}
                    </IonList>
                    :
                    <IonInput type="text" placeholder='keyword or phrase' value={Object.entries(filterChoicesRef.current.customAnswers).map(o => o[1][0]).join('')} onIonBlur={(e: IonInputCustomEvent<FocusEvent>) => handleSelection(`${index}-${(e.target.value as string).toLowerCase()}`, 'customAnswers', q[1].M.type.S)}  />
                  }
              </IonItem>
              <br />
            </Fragment>
          )
        })}
        
      </aside>
    </IonAccordion>
  );
  else if(isError && !(error as Object).toString().includes('SyntaxError')) return (
    <section>No custom questions at this time.</section>
  );
  else if(isLoading && isFetching) return (
    <IonSpinner name="dots" />
  );
  else return (
    <></>
  );
}

export default CustomQuestions;