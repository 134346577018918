import { useContext, Fragment, useLayoutEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonLoading, IonCard, IonCardTitle, IonCardContent, IonIcon } from '@ionic/react';
import { personCircleSharp } from 'ionicons/icons';
import AnalyticsTrend from './AnalyticsTrend';
import { useAuthContext } from '../hooks/authContext';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Filter from './Filter';
import AnalyticsCard from './AnalyticsCard';
import apiContext from '../services/api-service';
import { randomBytes } from 'crypto';
import { urls } from '../utilities/urls';
import '../style/ionic/Analytics.css';
import Avatar from 'react-avatar';

const Analytics = () => {

  const api = useContext(apiContext);
  const userAuth = useAuthContext();
  const history = useHistory();

  useLayoutEffect(() => {
    if(!userAuth.auth || userAuth.tier !== 'gold') history.push('/Home');
  });

  const selectOptions = {
    'Introverts': 'extraversion-low-factor',
    'Ambiverts': 'extraversion-medium-factor',
    'Extroverts': 'extraversion-high-factor',
    'Agreeable': 'agreeableness-high-factor',
    'Moderate': 'agreeableness-medium-factor',
    'Competitive': 'agreeableness-low-factor',
    'Industrious': 'conscientiousness-high-factor',
    'Determined': 'conscientiousness-medium-factor',
    'Laid Back': 'conscientiousness-low-factor',
    'Creative': 'openness-high-factor',
    'Inventive': 'openness-medium-factor',
    'Rigid': 'openness-low-factor',
    'Neurotic': 'neuroticism-high-factor',
    'Stable': 'neuroticism-medium-factor',
    'Calm': 'neuroticism-low-factor',
    'Humble': 'honesty-high-factor',
    'Confident': 'honesty-medium-factor',
    'Selfish': 'honesty-low-factor',
    'Married': 'relationship-married-stat',
    'Single': 'relationship-single-stat',
    'Partnered': 'relationship-relationship-stat'
  };

  const defaultTopRow = [
    { Label: 'Extroverts', Value: 'extraversion-high-factor'},
    { Label: 'Agreeable', Value: 'agreeableness-high-factor'},
    // { Label: 'Industrious', Value: 'conscientiousness-high-factor'}
  ];

  // const defaultSecondRow = [
  //   { Label: 'Creative', Value: 'openness-high-factor'},
  //   { Label: 'Neurotic', Value: 'neuroticism-high-factor'},
  //   // { Label: 'Humble', Value: 'honesty-high-factor'}
  // ];

  // useQuery to get total members, then pass the number down to the child AnalyticsCards
  const { data, isLoading, isFetching, isSuccess, isError, error } = useQuery('membersCountData', async () => {
    return await api.getData(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', '', userAuth.orgcode ?? '', 'table-count', '', userAuth.userDetails?.email ?? '', userAuth.jwtRef.current)
    .then(res => res)
    .catch(err => { throw new Error(err) });
  }, {
    enabled: (userAuth.orgcode !== undefined && !!userAuth.jwtRef.current && userAuth.tier === 'gold'),
    refetchOnWindowFocus: false,
    retry: 2
  });

  const randomString = () => {
    return randomBytes(8).toString('base64').slice(0, 8);
  }

  const filtration = () => {
    console.log('filter');
  }

  if(isLoading || isFetching) return (
    <Fragment>
      <IonLoading isOpen={true} />
    </Fragment>
  )

  if(isError) return (
    <section>
      <h1>Not today!</h1>
    </section>
  )

  if(isSuccess && data.Items) return (
    <section>
      {/* <Filter handleFiltration={filtration} /> */}
      <IonGrid>
        <IonRow>
          <IonCol sizeLg="3" sizeMd="4" sizeSm="12">
            <IonCard>
              <IonCardContent style={{textAlign: 'center'}}>
                <IonCardTitle className="members" >
                  <Avatar color="#FBBF51" value={data.Items[0].assessments.N} round={true} />
                </IonCardTitle>
                  <h2>Assessments Taken</h2>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeLg="3" sizeMd="4" sizeSm="12">
            <IonCard>
              <IonCardContent style={{textAlign: 'center'}}>
                <IonCardTitle className="members" >
                  <Avatar color="#B9E0EA" value={data.Items[0].matchesMade.N} round={true} />
                </IonCardTitle>
                <h2>Connections Made</h2>
              </IonCardContent>
            </IonCard>
          </IonCol>
          {defaultTopRow.map(({Label, Value}) => {
            return (
              <IonCol key={randomString()} sizeLg="3" sizeMd="2" sizeSm="12">
                <AnalyticsCard selectOptions={selectOptions} cardId={randomString()} totalMembers={data.Items[0].assessments.N} defaultLabel={Label} defaultValue={Value} />
              </IonCol>
            );
          })}
        </IonRow>
        {/* <IonRow>
          {defaultSecondRow.map(({Label, Value}) => {
            return (
              <IonCol key={randomString()} sizeLg="3" sizeMd="2" sizeSm="12">
                <AnalyticsCard selectOptions={selectOptions} cardId={randomString()} totalMembers={data.Count} defaultLabel={Label} defaultValue={Value} />
              </IonCol>
            );
          })}
        </IonRow> */}
        <IonRow>
          <AnalyticsTrend days="60"/>
        </IonRow>
      </IonGrid>
    </section>
  );

  else return (
    <section style={{padding: '2rem'}}>
      <h1>It looks like you haven't upgraded. Please contact Rivet to upgrade!</h1>
    </section>
  )
}

export default Analytics;