export const getMatchRankingColor = (rating: number) => {
  switch (true) {
    case (rating < 65) :
      return '#FF0000'; // bad
    case (rating < 75):
      return '#F36015'; // okay
    case (rating < 85):
      return '#1F85A0'; // great
    case (rating >= 85):
      return '#1B617B'; // excellent
  
    default:
      break;
  }
}

export const getMatchRanking = (rating: number) => {
  switch (true) {
    case (rating < 65) :
      return 'low'; // bad
    case (rating < 75):
      return 'okay'; // okay
    case (rating < 85):
      return 'great'; // great
    case (rating >= 85):
      return 'excellent'; // excellent
  
    default:
      break;
  }
}