import * as AWSCognito from 'amazon-cognito-identity-js';
import { createContext } from 'react';
import { CognitoAttributeTokenTypes } from '../AuthProvider';
import { env } from '../config';

interface RequiredAttributeTypes {
  family_name: string
  given_name: string
  'custom:organization': string
}

export interface VerificationTypes {
  email: string | number | null | undefined,
  verificationCode: string | number | null | undefined,
  newPassword: string | number | null | undefined
}

export interface AuthAttributeTypes {
  userAttributes: RequiredAttributeTypes
  authDetails: AWSCognito.AuthenticationDetails
  cognitoUser: AWSCognito.CognitoUser
}

class CognitoService {

  _POOL_DATA: AWSCognito.ICognitoUserPoolData = {
    UserPoolId: env.REACT_APP_USER_POOL_ID,
    ClientId: env.REACT_APP_CLIENT_ID
  };  
  _userPool: AWSCognito.CognitoUserPool;

  constructor() {
    this._userPool = new AWSCognito.CognitoUserPool(this._POOL_DATA);
  }

  authenticate = (email: string, password: string): Promise<boolean | AuthAttributeTypes> => {
    return new Promise((resolve, reject) => {

      const authDetails = new AWSCognito.AuthenticationDetails({
        Username: email,
        Password: password
      });

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this._userPool
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: () => {
          resolve(true);
        },
        onFailure: () => {
          reject(false);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          // resolve({userAttributes: {given_name: userAttributes.given_name, family_name: userAttributes.family_name, 'custom:organization': userAttributes.organization} as RequiredAttributeTypes, authDetails: authDetails, cognitoUser: cognitoUser});
          resolve({userAttributes: userAttributes, authDetails: authDetails, cognitoUser: cognitoUser});
        }
      });
    });
  }

  completePasswordChallenge = (newPw: string, authAttributes: AuthAttributeTypes) => {
    return new Promise((resolve, reject) => {
      authAttributes.cognitoUser.completeNewPasswordChallenge(newPw, {}, {
        onSuccess: (result) => {
          console.log('pass challenge: ', result);
          resolve(result);
        },
        onFailure: (error) => {
          console.error('pass challenge fail: ', error);
          reject(error);
        }
      });
    })
  }

  isUserAuthenticated = () => {
    return new Promise<boolean>((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      if(!cognitoUser) return reject(false);
      cognitoUser.getSession((err: Error | null) => {
        // console.log('stashed jwt token: ', session.getIdToken().getJwtToken());
        if(err) return reject(false);
        resolve(true);
      });
    });
  }

  getUserAttrs = () => {
    return new Promise<CognitoAttributeTokenTypes>((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
  
      // getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser?.getSession((err: Error | null, session: AWSCognito.CognitoUserSession | null) => {
        if(err) return reject(err);
        // console.log(`session validity: ${session?.isValid()}`);
        
        cognitoUser.getUserAttributes((err, attrs) => {
          if(err) return reject(err);
          resolve({attrs, jwt: session?.getIdToken().getJwtToken()});
        });
      });
    });
  }

  getUsername = () => {
    const cognitoUser = this._userPool.getCurrentUser()
    return cognitoUser?.getUsername();
  }

  getUserData = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      // getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser?.getSession((err: Error | null, session: AWSCognito.CognitoUserSession | null) => {
        cognitoUser?.getUserData((err, data) => {
          if (err) {
            alert(err.message || JSON.stringify(err));
            reject(err);
          }
          console.log('User data for user ', data);
          resolve(data);
        });
      });
    })
  }

  logout = () => {
    const cognitoUser = this._userPool.getCurrentUser();
    cognitoUser?.signOut();
  }

  // FORGOT PASSWORD FLOW
  forgotPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this._userPool
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          // successfully initiated reset password request
          resolve(data);
        },
        onFailure: (err) => {
          alert(err.message ?? JSON.stringify(err));
          reject(err);
        }
      })
    });
  }

  confirmPassword = ({email, verificationCode, newPassword}: VerificationTypes) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email as string,
        Pool: this._userPool
      });

      cognitoUser.confirmPassword(verificationCode as string, newPassword as string, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          console.error('failed confirming pw: ', err);
          reject(err);
        }
      })
    });
  }
  // END FORGOT PASSWORD FLOW

}

// set up Cognito context (Cognito package will take care of state)
const csInstance = new CognitoService();
const CognitoContext = createContext(csInstance);

export default CognitoContext;