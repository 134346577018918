import { IonLoading, IonNote, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import ProfileCard from './ProfileCard';
import PercentBar from './PercentBar';
import { chevronBackOutline } from 'ionicons/icons';
import Avatar from 'react-avatar';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { MemberTypes, SingleListTypes } from './Members';
import { urls } from '../utilities/urls';
import archetypes from '../data/archetypes.json';
import './Member.css';
import { ArchetypesTypes } from './interfaces/archetypes';
import RemoveMember from './RemoveMember';
import { useAuthContext } from '../hooks/authContext';
import { useApiContext } from '../hooks/apiContext';
import { List, StringType } from './interfaces/questions-types';

interface MemberParams {
  id: string
}

export interface DynamoStringTypes {
  S: string
}
export interface DynamoNumberTypes {
  N: number
}

export interface DynamoBoolTypes {
  BOOL: boolean
}

export interface HexacoMapTypes {
  conscientiousness: DynamoNumberTypes
  honesty: DynamoNumberTypes
  openness: DynamoNumberTypes
  neuroticism: DynamoNumberTypes
  latestAssessment: DynamoStringTypes
  extraversion: DynamoNumberTypes
  agreeableness: DynamoNumberTypes
}

export interface MbtiListTypes {
  0: DynamoStringTypes
  1: DynamoStringTypes
  2: DynamoStringTypes
  3: DynamoStringTypes
  4: DynamoStringTypes
  5: DynamoNumberTypes
  6: DynamoStringTypes
  7: DynamoStringTypes
}

export interface HexacoTypes {
  M: HexacoMapTypes
}

export interface MbtiTypes {
  L: MbtiListTypes
}

export interface DynamoListStringTypes {
  L: SingleListTypes
}

interface SingleMemberTypes extends MemberTypes {
  hexaco: HexacoTypes
  mbti: MbtiTypes
  employment: DynamoListStringTypes
  industry: DynamoListStringTypes
  relationship: DynamoListStringTypes
  archetype: DynamoStringTypes
  connectionIds: List
}

interface SingleMemberBoolType {
  alreadyViewed: boolean
  email: string
}

const Member = ({ id }: MemberParams) => {
  const api = useApiContext();
  const app = useAuthContext();
  const { state } = useLocation<SingleMemberBoolType>();
  const history = useHistory();

  const handleMatchesClick = (id: string, fname: string | undefined, lname: string | undefined, email: string | undefined) => {
    history.push({
      pathname: '/membermatches',
      state: { user1Id: id, name: fname, last: lname, email: email }
    });
  }

  const handleConnectionsClick = (id: string, email: string | undefined, fname: string | undefined, cIds: StringType[]) => {
    history.push({
      pathname: '/memberconnections',
      state: { user1Id: id, email: email, name: fname, cIds }
    });
  }

  const { isLoading, error, data, isFetching, isSuccess, isError } = useQuery<SingleMemberTypes, Error>([`SINGLE_USER_DASHBOARD`, id], async () => {
    return await api.getMember(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', id, state.alreadyViewed?.toString() ?? true, state.email, app.jwtRef.current)
      .then(res => res)
      .catch(err => {
        console.log(err)
        throw new Error(err)
      });
  },
    {
      refetchOnWindowFocus: false,
      retry: 2
    })

  if (isLoading || isFetching) return <aside><IonLoading isOpen={true}></IonLoading></aside>;

  if (error && isError) return <aside>There was an error: {error.message}</aside>;

  if (isSuccess && data && Object.keys(data).length > 0) return (
    <section style={{ fontFamily: 'Satoshi-Regular, sans-serif' }}>
      <IonButton onClick={() => history.goBack()} fill="clear">
        <IonIcon color="dark" slot="icon-only" icon={chevronBackOutline} />
        <span style={{ color: "black" }}>Back</span>
      </IonButton>
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
            <IonGrid>
              <IonRow>
                <IonCol push="1" sizeXl="4" sizeLg="5" sizeMd="6" sizeSm="12">
                  <aside style={{ "marginLeft": "2.5rem" }}>
                    <Avatar src={`${archetypes[`${data.archetype?.S}` as keyof ArchetypesTypes].image_url}`} name={`${data.user.M.fname.S} ${data.user.M.lname.S}`} />
                  </aside>
                </IonCol>
                <IonCol sizeXl="6" sizeLg="6" sizeMd="6" sizeSm="12">
                  <aside style={{ marginLeft: "1.5rem", textTransform: "capitalize" }}>
                    <h1>{data.user.M.fname.S} {data.user.M.lname.S}</h1>
                    <h5>Age: {
                      (() => {
                        if (data.user.M.dobyear) {
                          const dateFormatted = `${data.user.M.dobyear.S}-${data.user.M.dobmonth.S}-${data.user.M.dobday.S}`;
                          const now = new Date();
                          const bday = new Date(dateFormatted);
                          return ((now.getFullYear() - bday.getFullYear()) + ((now.getMonth() - bday.getMonth()) > 0 ? 0 : ((now.getDay() - bday.getDay()) > 0 ? 0 : -1))).toString();
                        } else return data.user.M.age.S;
                      })()
                    }</h5>
                  </aside>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol push="1" sizeLg="9" sizeMd="9" sizeSm="12">
                  <RemoveMember id={id} oc={app.orgcode} email={data.user.M.email.S} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol push="1" sizeLg="6" sizeMd="4" sizeSm="12">
                  <IonNote>Last assessment: {new Date(data.hexaco.M.latestAssessment.S.toLocaleUpperCase()).toLocaleDateString()} </IonNote>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol push="1" sizeLg="12" sizeMd="12" sizeSm="12">
                  <section className="member">
                    {Object.entries(data.hexaco.M).sort().filter((([k, v]) => k !== 'latestAssessment')).map(([key, value], index) => {
                      if (key === 'neuroticism') key = 'emotionality';
                      else if (key === 'honesty') key = 'Honesty/Humility'
                      return <PercentBar key={index} title={key} hexNumber={value.N} denominator={30} />
                    })
                    }
                  </section>
                  <section style={{ width: "80%" }}>
                    <h3>Archetype: <span style={{ textTransform: 'capitalize' }}>{data.archetype.S}</span></h3>
                    {Object.entries(archetypes[`${data.archetype.S ?? ''}` as keyof ArchetypesTypes].description)
                      .map(([k, v], index) => {
                        return (
                          <aside key={index}>
                            <p><strong style={{ textTransform: 'capitalize' }}>{k} - </strong>{v}</p>
                          </aside>
                        );
                      })}
                  </section>
                  <section>
                    <h3>Meeting Notes</h3>
                    <p style={{ whiteSpace: "pre-line" }}>{archetypes[`${data.archetype.S ?? ''}` as keyof ArchetypesTypes].meeting_notes}</p>
                  </section>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol sizeXl="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
            <IonGrid>
              <ProfileCard title="Life Stage and Demographics" subtitle={data.activeConnections?.N.toLocaleString()} list={[{ employment: data.employment?.L[0]?.S }, { industry: data.industry?.L[0]?.S }, { relationship: data.relationship?.L[0]?.S }, { email: data.user.M.email.S }]} />
              <IonRow>
                <IonCol pushXl="1" sizeXl="4" sizeLg="12" sizeMd="12" sizeSm="12" >
                  <IonButton onClick={() => handleMatchesClick(id, data.user.M.fname.S, data.user.M.lname.S, data.user.M.email.S)} >View Matches</IonButton>
                </IonCol>
                <IonCol sizeXl="4">
                  <IonButton disabled={data.connectionIds.L.length <= 0} onClick={() => handleConnectionsClick(id, data.user.M.email.S, data.user.M.fname.S, data.connectionIds.L)} >View Connections</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </section>
  );

  else return <aside>Unfortunately, something went wrong...<Link to="/">click here</Link></aside>
}

export default Member;