import { IonNote, IonSpinner, IonTitle, IonProgressBar, IonButton } from "@ionic/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { calcAutoConnectWait } from "../../utilities/calcWaitTime";
import { useQuery } from "react-query";
import { urls } from "../../utilities/urls";
import { useApiContext } from "../../hooks/apiContext";
import { AuthContextTypes } from "../../AuthProvider";

interface LoadingBodyTypes {
  dismiss: (data: string) => void;
  auth: AuthContextTypes
}

const LoadingBody = ({ auth, dismiss }: LoadingBodyTypes) => {

  const api = useApiContext();

  const calculatedProgress = useRef<number>(calcAutoConnectWait(1));
  const increment = useRef<number>(0);

  const { data, isLoading, isError, isSuccess } = useQuery('memberCountsData', async () => {
    return await api.getData(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', '', auth.orgcode ?? '', 'table-count', '', auth.userDetails?.email ?? '', auth.jwtRef.current);
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  })

  if(isSuccess) {
    calculatedProgress.current = calcAutoConnectWait(data?.Items[0]?.assessments?.N ?? 1);
    increment.current = 1/calculatedProgress.current;
  }

  const [buffer, setBuffer] = useState(0);
  const [progress, setProgress] = useState(0);

  let interval: NodeJS.Timeout;
  useEffect(() => {
    if(isSuccess) {
      interval = setInterval(() => {
        setBuffer((prevBuffer) => prevBuffer + (increment.current * 1.2));
        setProgress((prevProgress) => prevProgress + increment.current);
      }, 1000);
      if((progress*calculatedProgress.current) >= calculatedProgress.current) clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [progress, isSuccess, buffer]);

  if(isLoading) return <aside>Loading...</aside>

  if(isError) return <aside>There was an error. Please try again later.</aside>

  else return (
    <section style={{padding: '2rem'}}>
      <IonTitle>Automagically connecting members...</IonTitle>
      <br />
      <p>Our AI <span role="img" aria-label="robot">🤖</span> is working in the background to make the connections, so give it a few minutes before you see changes in the dashboard.</p>
      <p>Expect a report afterwards of those that were not connected or had fewer connections than expected.</p>
      <IonNote>Typically, you may have to wait up to 6 minutes per 1,000 members.</IonNote>
      <br />
      <aside style={{textAlign: 'center', marginTop: '11rem'}}>
        {/* <IonSpinner style={{transform: 'scale(5)'}} name="bubbles" color="tertiary"/> */}
        {(progress*calculatedProgress.current) >= calculatedProgress.current ?
         <Fragment>
          <h3>Auto Connect complete!</h3>
           <IonButton onClick={() => dismiss('confirm')}>Download report</IonButton>
           <IonButton onClick={() => dismiss('dismiss')}>Dismiss</IonButton>
         </Fragment>
         :
         <Fragment>
          <IonProgressBar value={progress} buffer={buffer} />
         </Fragment>
        }
      </aside>
    </section>
  );
}

export default LoadingBody;