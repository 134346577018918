// Self contain: styling, types, and markup
import { CSSProperties, useState, useImperativeHandle, Ref, forwardRef, useRef } from 'react';
import { useIonAlert, IonLoading } from '@ionic/react';
import SidesheetContent, { UsersContentTypes } from './SidesheetContent';
import { useMutation, useQueryClient } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { CurrentUserTypes } from './MemberMatches';
import { urls } from '../utilities/urls';
import { capitalize } from '../utilities/text';
import '../style/ionic/Sidesheet.css';

interface SidesheetTypes {
  matchType: string
}

export interface chosenUserTypes {
  show?: boolean | undefined
  user2Id: string
  fname: string | undefined
  lname: string | undefined
  score: number
  orgcode: string
  email2: string
  handleConnection?: (chosen: chosenUserTypes, currentUser: CurrentUserTypes, connectionStrength: number) => void
}

export interface SideSheetHandle {
  handleShow: (options: chosenUserTypes, user: CurrentUserTypes, connectionStrength: number) => void
}

export const side: CSSProperties = {
  position: 'fixed',
  textAlign: 'center',
  width: '0',
  height: '100vh',
  right: '0',
  top: '0',
  overflowX: 'hidden',
  transition: 'all ease-in-out 0.2s',
  transitionProperty: 'width',
  paddingLeft: '0.5rem'
}

const Sidesheet = forwardRef(({ matchType }: SidesheetTypes, ref: Ref<SideSheetHandle>) => {
  const api = useApiContext();
  const queryClient = useQueryClient();
  const authContext = useAuthContext();
  const [present] = useIonAlert();
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation(async (users: UsersContentTypes) => {
    await api.makeConnection(process.env.NODE_ENV !== 'production' ? urls.match : '/v1/calculate-match-user', users, authContext.orgcode, matchType, authContext.jwtRef.current).then(res => res).catch(err => { throw new Error(err) });
  },
    {
      onSuccess: () => {
        present({
          header: 'Connection Successful!',
          message: `Be on the lookout for an email that will introduce ${capitalize(options.fname)} and ${capitalize(currentUserRef.current.name)}`,
          buttons: [
            'Cancel',
            {
              text: 'Ok', handler: () => {
                queryClient.resetQueries(`matchedMembers-${matchType}`);
                queryClient.resetQueries('SINGLE_USER_DASHBOARD');
              }
            },
          ],
          onDidDismiss: () => {
            queryClient.resetQueries(`matchedMembers-${matchType}`);
            queryClient.resetQueries('SINGLE_USER_DASHBOARD');
          }
        });
      },
      onError: () => {
        present({
          header: 'Connection Unsuccessful',
          message: 'It looks like there was an error. Please try again later.',
          cssClass: 'alert-unsuccessful',
          onDidDismiss: () => {
            queryClient.resetQueries(`matchedMembers-${matchType}`);
            queryClient.resetQueries('SINGLE_USER_DASHBOARD');
          }
        })
      },
      onSettled: () => setIsLoading(false)
    })

  const handleConnection = (chosen: chosenUserTypes, currentUser: CurrentUserTypes, connectionStrength: number) => {
    mutation.mutate({ toggleSidesheet, chosen: chosen, user: currentUser, admin: authContext.userDetails, connectionStrength: connectionStrength });
    setIsLoading(true);
  }

  const [options, setOptions] = useState<chosenUserTypes>({ show: false, handleConnection: handleConnection } as chosenUserTypes);
  const currentUserRef = useRef({} as CurrentUserTypes);
  const connectionStrengthRef = useRef(0);

  const toggleSidesheet = (ssOptions: chosenUserTypes, user: CurrentUserTypes, connectionStrength: number) => {
    setOptions(opts => ({ ...opts, show: !opts.show, ...ssOptions })); // chosen
    currentUserRef.current = user; // current
    connectionStrengthRef.current = connectionStrength;
  }

  useImperativeHandle(ref, () => {
    return {
      handleShow: toggleSidesheet
    };
  });

  if (isLoading) return <IonLoading isOpen />

  return (
    <aside style={options.show ? (window.innerWidth > 600) ? { ...side, width: '32vw' } : { ...side, width: '80vw' } : side}>
      <SidesheetContent toggleSidesheet={toggleSidesheet} chosen={options} user={currentUserRef.current} connectionStrength={connectionStrengthRef.current} />
    </aside>
  );
});

export default Sidesheet;