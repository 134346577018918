import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthContext } from '../hooks/authContext';
import PaymentEditing from './Enterprise/PaymentEditing/PaymentEditing';

const Account = () => {
  const userAuth = useAuthContext();
  const history = useHistory();

  useLayoutEffect(() => {
    if(!userAuth.auth) history.push('/Home');
  });

  return (
    <section style={{padding: '2rem'}}>
      <PaymentEditing />
    </section>
  );
}

export default Account;