import { CSSProperties } from 'react';
export const sectionContainer: CSSProperties = {
  margin: '2rem',
  fontFamily: 'Satoshi-Regular, sans-serif'
};

export const pageTitle: CSSProperties = {
  fontSize: '2.5rem'
};

export const subContainer: CSSProperties = {
  margin: '1rem 1.5rem',
  width: '50%'
}

export const tabs: CSSProperties = {
  textTransform: 'capitalize',
  fontSize: '1.3rem',
  paddingLeft: '2rem',
  fontWeight: 'bold',
  cursor: 'pointer',
  textUnderlineOffset: '0.4rem',
  color: 'black'
};

export const selectedTab: CSSProperties = {
  ...tabs,
  textDecoration: 'underline solid 3px',
  color: "#388079"
};