import { useLayoutEffect, useRef } from 'react';
import { IonLabel, IonIcon, IonList, IonItem, IonToggle, IonNote, IonButton, IonSelect, IonSelectOption } from '@ionic/react';
import { copy, helpCircle, playCircleOutline } from 'ionicons/icons';
import { useAuthContext } from '../hooks/authContext';
import { useHistory } from 'react-router';
import rivetLogoDark from '../resources/02_Logo_White.svg';
import rivetLogoLight from '../resources/01_Logo_Black.svg';
import '../style/ionic/Settings.css';
import MatchOptions from './MatchOptions';
// import PaymentEditing from './Enterprise/PaymentEditing/PaymentEditing';

const Settings = () => {
  const app = useAuthContext();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    if (!app.auth) history.push('/Login');
    parameterCheck();
  });

  const summonTip = (val: string) => {
    switch (val) {
      case 'activeConnections':
        alert('This limits members with more than the provided amount of active connections from appearing in the matches section for a given member. Ex: If you set this to 1, only members with 0 active connections will be displayed in the matches section.');
        break;
      case 'memberMatches':
        alert('This limits the number of matches for a given member from appearing in the matches section. Ex: If you set this to 3, only up to 3 other members can be displayed in the matches section.');
        break;
      case 'addedQuestions':
        alert('This feature adds specific questions to the assessment. Use the answers to segment your members by mentors and mentees, for example.');
        break;
      default:
        break;
    }
  }

  const getOrgcodeUrl = () => {
    inputRef.current?.select();
    inputRef.current?.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(inputRef.current?.value ?? '').then(
      () => alert('Assessment url copied!'),
      () => alert('An error occurred, please try again.')
    );
  }

  const addParameterToUrl = (param: string, isToggled: boolean) => {
    // save parameter in storage
    localStorage.setItem(`${app.orgname.current}-${param}`, JSON.stringify(isToggled));
    if (isToggled && inputRef.current) inputRef.current.value = `${inputRef.current?.value}&${param}=1`;
    else if (!isToggled && inputRef.current) inputRef.current.value = inputRef.current?.value.split(`&${param}=1`).join('');
  }

  const parameterCheck = () => {
    ['buddy', 'mentor', 'years', 'department', 'intern'].forEach(p => {
      addParameterToUrl(p, JSON.parse(localStorage.getItem(`${app.orgname.current}-${p}`) ?? 'false'));
    });
  }

  return (
    <section style={{ padding: '2rem' }}>
      <picture>
        <source srcSet={rivetLogoDark} media="(prefers-color-scheme:dark)" />
        <img className="menu-logo" src={rivetLogoLight} alt="Rivet" />
      </picture>
      <h1 className="hide-mobile"><span role="img" aria-label="rocket">🚀</span> This is where we begin <span role="img" aria-label="rocket">🚀</span></h1>
      <aside className="explanation-container">
        <h3><span role="img" aria-label="arrow-down">⬇️</span> Send the <strong>Assessment URL</strong> to your participants <span role="img" aria-label="arrow-down">⬇️</span></h3>
        <input className="input-url" ref={inputRef} type="text" value={`https://app.workrivet.com/assessment?oc=${app.orgcode}`} readOnly />
        <IonButton fill="solid" size="small" style={{ marginBottom: "0.9rem" }} onClick={getOrgcodeUrl}><IonIcon color="dark" icon={copy} /> Copy</IonButton>
        <IonNote style={{ marginBottom: "0.5rem", marginTop: "0" }}>*If you want to add questions to your assessment, please choose the ones below before copying URL.</IonNote>
        <h5>Your org code: <span>{app.orgcode}</span></h5>
        <br />
        <IonButton type="button" href="https://www.workrivet.com/how-it-works#comp-lbndfwa2" target="__blank" rel="noreferrer noopener">Video Tutorial <IonIcon icon={playCircleOutline} /></IonButton>
      </aside>
      <br />

      <IonLabel>Limit number of active connections when matching to the following:</IonLabel><IonIcon onClick={() => summonTip('activeConnections')} icon={helpCircle} />
      <IonSelect style={{ marginBottom: '1.5rem' }} className="settings-select" defaultValue={app.settings?.connectionsLimit ?? '3'} placeholder={app.settings?.connectionsLimit?.toString() ?? '3'} onIonChange={e => app.changeNumberTypeSetting && app.changeNumberTypeSetting('connectionsLimit', parseInt(e.detail.value, 10))}>
        {Array(20).fill(0).map((e, index) => <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>)}
      </IonSelect>

      <IonLabel>Limit number of member matches for each member to the following:</IonLabel><IonIcon onClick={() => summonTip('memberMatches')} icon={helpCircle} />
      <IonSelect className="settings-select" defaultValue={app.settings?.matchesToShow ?? '10'} placeholder={app.settings?.matchesToShow?.toString() ?? '10'} onIonChange={e => app.changeNumberTypeSetting && app.changeNumberTypeSetting('matchesToShow', parseInt(e.detail.value, 10))}>
        <MatchOptions />
      </IonSelect>

      {app.tier === 'gold' &&
        <aside>
          <hr />
          <h2><span role="img" aria-label="medal-1">🥇</span> Select a question to add to your assessment.</h2>
          <IonNote><strong>Note:</strong> You must make this decision before a round of assessments. <IonIcon onClick={() => summonTip('addedQuestions')} icon={helpCircle} /></IonNote>
          <hr />
          <IonList>
            <IonItem>
              <IonLabel>Are you a new hire? &#40; Less than one year &#41;</IonLabel>
              <IonToggle checked={JSON.parse(localStorage.getItem(`${app.orgname.current}-buddy`) ?? 'false')} onIonChange={(e) => addParameterToUrl('buddy', e.target.checked)} mode="ios" slot="start"></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>How long have you been at the company?</IonLabel>
              <IonToggle checked={JSON.parse(localStorage.getItem(`${app.orgname.current}-years`) ?? 'false')} onIonChange={(e) => addParameterToUrl('years', e.target.checked)} mode="ios" slot="start"></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>Are you a mentor or a mentee?</IonLabel>
              <IonToggle checked={JSON.parse(localStorage.getItem(`${app.orgname.current}-mentor`) ?? 'false')} onIonChange={(e) => addParameterToUrl('mentor', e.target.checked)} mode="ios" slot="start"></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>Which department are you in?</IonLabel>
              <IonToggle checked={JSON.parse(localStorage.getItem(`${app.orgname.current}-department`) ?? 'false')} onIonChange={(e) => addParameterToUrl('department', e.target.checked)} mode="ios" slot="start"></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>Are you an intern?</IonLabel>
              <IonToggle checked={JSON.parse(localStorage.getItem(`${app.orgname.current}-intern`) ?? 'false')} onIonChange={(e) => addParameterToUrl('intern', e.target.checked)} mode="ios" slot="start"></IonToggle>
            </IonItem>
          </IonList>
        </aside>
      }
    </section>
  );
}

export default Settings;