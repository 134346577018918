import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonNote, IonRow, IonSpinner } from "@ionic/react";
import { useMutation, useQuery } from "react-query";
import { ScheduleTypes } from "../interfaces/autoConnectSchedule";
import { useApiContext } from "../../hooks/apiContext";
import { AuthContextTypes } from "../../AuthProvider";
import { urls } from "../../utilities/urls";
import { GwApiService } from "../../services/api-service";

interface AutoConnectListProps {
  auth: AuthContextTypes
  api: GwApiService
}

const AutoConnectList = ({ auth, api }: AutoConnectListProps) => {

  const { mutate } = useMutation(async (scheduleName: string) => {
    const response = await api.deleteSchedule(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 'delete-schedule', auth.orgcode, scheduleName, auth.jwtRef.current!);
    refetch();
    return response;
  })

  const { data, isSuccess, isLoading, isFetching, isError, refetch } = useQuery<ScheduleTypes[]>('auto-connect-list', async () => {
    return await api.listSchedules(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 'list-schedules', auth.orgcode!, auth.jwtRef.current!);
  }, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  if (isLoading || isFetching) return <IonSpinner name="lines-sharp" />;
  else if (isError) return <p>There was an error. Check back later.</p>;
  else if (isSuccess) return (
    <section style={{marginBottom: "0.5rem"}}>
      {data.length === 0 ? <IonNote>No scheduled auto connections</IonNote>
      :
      <IonList lines="full">
        {
          data.map((i: ScheduleTypes) => ( 
            <IonItem key={i.scheduleName.S}>
              <IonGrid>
                <IonRow>
                  <IonCol><span><strong>Scheduled for</strong>: {new Date(i.settings.M.datetime.S).toLocaleString()}</span></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel position="stacked">Settings:</IonLabel>
                    <span><strong>Connections #</strong>: {i.settings.M.settings.M.connections.N}</span>
                    <span> | <strong>Strength Limit</strong>: {i.settings.M.settings.M.strength.S}</span>
                    <span> | <strong>Matches #</strong>: {i.settings.M.settings.M.matches.N}</span>
                    <span> | <strong>Connections Limit</strong>: {i.settings.M.settings.M.connectionsLimit.N}</span>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonButton onClick={() => mutate(i.scheduleName.S)} slot="end" color="danger">Delete</IonButton>
            </IonItem>
          ))
        }
      </IonList>
      }
    </section>
  );
  else return <p>There was an error. Check back later.</p>;
}

export default AutoConnectList;