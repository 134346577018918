import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Menu from './components/Menu';
import Page from './pages/Page';
import AuthProvider from './AuthProvider';
import './style/ionic/App.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import 'typeface-manrope';
import './theme/fonts.css';

setupIonicReact({
  mode: 'ios'
});

const App: React.FC = () => {

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <AuthProvider>
              <Menu />
              <IonRouterOutlet id="main">
                <Route path="/" exact={true}>
                  <Redirect to="/Home" />
                </Route>
                <Route path="/:name" exact={true}>
                  <Page />
                </Route>
                <Route path="/:name/:userId" exact={true}>
                  <Page />
                </Route>
              </IonRouterOutlet>
            </AuthProvider>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </QueryClientProvider>
  );
};

export default App;
