import { SelectChangeEventDetail } from '@ionic/core';
import { IonSelect, IonSelectOption, IonButton, IonLoading } from '@ionic/react';
import { useLayoutEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from '../../hooks/apiContext';
import { useAuthContext } from '../../hooks/authContext';
import { useCognitoContext } from '../../hooks/cognitoContext';
import { urls } from '../../utilities/urls';
import { StringTypes } from '../Members';
import '../../style/ionic/Login.css';
import { loginContainer, loginContainerMobile } from '../../style/ts/LoginCSS';
import { useHistory } from 'react-router';
import { capitalizeAllFirstWords } from '../../utilities/text';

// L: [{M: orgname: {S: 'xxx'}, orgcode: {S: 'xxx'}}, ...]
interface EnterpriseOrgTypes {
  orgname: StringTypes,
  orgcode: StringTypes
}
interface EnterpriseMapTypes {
  M: EnterpriseOrgTypes
}
interface EnterpriseListTypes {
  L: EnterpriseMapTypes[]
}

const Orgchoice = () => {

  const history = useHistory();
  const auth = useAuthContext();
  const api = useApiContext();
  const cognito = useCognitoContext();
  const selectedVal = useRef<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (!auth.auth) history.push('/Home');
  }, [])

  const { data, isError, isSuccess, isLoading } = useQuery<EnterpriseListTypes, Error>('org-data', async () => {
    return await api.getEnterpriseOrgs(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', cognito.getUsername(), auth.userDetails?.email, auth.jwtRef.current);
  }, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!auth.userDetails?.email
  });

  const handleChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    selectedVal.current = e.detail.value;
    auth.orgname.current = data?.L.find(({ M: { orgcode } }) => orgcode.S === selectedVal.current)?.M.orgname.S;
  }

  const handleSubmit = () => {
    if (typeof selectedVal.current !== 'string') return;
    sessionStorage.setItem("current_orgname", auth.orgname.current || "")
    auth.enterpriseLogin && auth.enterpriseLogin(selectedVal.current ?? '');
  }

  if (isSuccess) return (
    <section style={(window.innerWidth < 600) ? loginContainerMobile : loginContainer} className="satoshi">
      <h2>Please choose the organization you wish to work on.</h2>
      <br />
      <IonSelect style={{ border: '2px solid black' }} placeholder="Please choose an org" onIonChange={handleChange}>
        {data?.L
          .sort((a, b) => a.M.orgname.S!.localeCompare(b.M.orgname.S!))
          .map(({ M: { orgname, orgcode } }) => <IonSelectOption key={orgcode.S} value={orgcode.S}>{capitalizeAllFirstWords(orgname.S)}</IonSelectOption>)}
      </IonSelect>
      <br />
      <IonButton mode="ios" onClick={handleSubmit}>Submit</IonButton>
    </section>
  )

  else if (isError) return (
    <section>
      <h1>There was an error, please try again later...</h1>
    </section>
  )

  else if (isLoading) return (
    <IonLoading spinner="bubbles" isOpen />
  )

  else return (
    <section>
      <h1>There was an error, please try again later...</h1>
    </section>
  )
}

export default Orgchoice;