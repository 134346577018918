import { env } from '../config';

export const urls = {
  dashboard: env.REACT_APP_API_URL_DASHBOARD,
  segments: env.REACT_APP_API_URL_SEGMENTS,
  match: env.REACT_APP_API_URL_MATCH,
  auto: env.REACT_APP_API_URL_AUTO,
  payment: env.REACT_APP_API_URL_PAYMENT
};

export const keys = {
  akey: env.REACT_APP_API_KEY
};