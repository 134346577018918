import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import Members from '../components/Members';
import Analytics from '../components/Analytics';
import Login from '../components/Login';
import Settings from '../components/Settings';
import OptOut from '../components/OptOut';
import './Page.css';
import NewPassword from '../components/NewPassword';
import Orgchoice from '../components/Enterprise/Orgchoice';
import MemberMatches from '../components/MemberMatches';
import MemberConnections from '../components/MemberConnections';
import Member from '../components/Member';
import Segmentation from '../components/Enterprise/Segmentation';
import Account from '../components/Account';
import { useLocation } from 'react-router-dom'
const Page: React.FC = () => {

  const { name, userId } = useParams<{ name: string; userId: string }>();
  const location = useLocation()
  const history = useHistory();

  const notAllowedPaths = ['/Orgchoice', '/Login']

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="satoshi">
        <IonHeader collapse="condense">
          <IonToolbar>
            <aside>
              <IonTitle className={`page-title ${name === 'Login' ? 'small-title' : ''}`} size="large">{name}{name === 'Login' ? ' - Admin Dashboard' : ''}</IonTitle>
            </aside>
            {(sessionStorage.getItem("current_orgname") && !notAllowedPaths.includes(location.pathname)) &&
              <aside slot="end">
                <h2 className="satoshi orgname-text">{sessionStorage.getItem("current_orgname")}</h2>
                <IonButton className="switch-dash" onClick={() => history.push('/Orgchoice')}>Switch Dashboard</IonButton>
              </aside>
            }
          </IonToolbar>
        </IonHeader>
        {(() => {
          switch (name) {
            case 'Members':
              return <Members />

            case 'Member':
              return <Member id={userId} />

            case 'Analytics':
              return <Analytics />

            case 'Home':
              return <Settings />

            case 'Settings':
              return <Settings />

            case 'Account':
              return <Account />

            case 'Optout':
              return <OptOut />

            case 'NewPassword':
              return <NewPassword />

            case 'Orgchoice':
              return <Orgchoice />

            case 'membermatches':
              return <MemberMatches />

            case 'memberconnections':
              return <MemberConnections />

            case 'Segmentation':
              return <Segmentation />

            default:
              return <Login />
          }
        })()}
      </IonContent>
    </IonPage>
  );
};

export default Page;
