import '../../style/ionic/AutoMatch.css';

import { SelectChangeEventDetail } from '@ionic/core';
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonNote, IonSelect, IonSelectOption, IonToggle, useIonModal } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { reloadCircleSharp } from 'ionicons/icons';
import { Parser } from 'json2csv';
import { Fragment, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useApiContext } from '../../hooks/apiContext';
import { getAutoSettings, saveAutoSettings } from '../../utilities/localSave';
import { urls } from '../../utilities/urls';
import { CustomQuestionTypes } from '../interfaces/questions-types';
import AutoConnectSchedule from './AutoConnectSchedule';
import { AutoMatchBodyTypes } from './AutoMatch';
import LoadingBody from './AutoMatchLoading';
import MatchOptions from '../MatchOptions';

export interface AutoMatchTypes {
  connections: number
  matches: number
  strength: string
  connectionsLimit: number,
  noMatchIndex?: number;
  name?: string;
  organization?: string;
  email?: string;
  doNotMatchSameAnswer: boolean;
}

const AutoMatchSettings = ({ dismissModal, dismissModalInvalidate, auth, toSchedule }: AutoMatchBodyTypes) => {
  const api = useApiContext();

  const [present, dismiss] = useIonModal(LoadingBody, {
    dismiss: (data: any, role: string) => { dismiss(data, role) },
    auth
  });

  const { data } = useQuery<CustomQuestionTypes>('custom-questions', async () => {
    return api.getCustomQuestions(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_QUESTIONS_URL! : '/v1/custom-questions', auth.orgcode, auth.jwtRef.current);
  }, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!auth.jwtRef.current
  })

  const [advanced, setAdvanced] = useState(false);
  const [customQuestions, setCustomQuestions] = useState(false);
  const [settings, setSettings] = useState<AutoMatchTypes>(getAutoSettings() ? getAutoSettings() as AutoMatchTypes : {
    connections: 1,
    strength: 'Okay',
    matches: 50,
    connectionsLimit: 3,
    noMatchIndex: 0,
    email: auth.userDetails?.email,
    name: auth.orgname.current,
    organization: auth.orgcode,
    doNotMatchSameAnswer: false
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const json2csvParser = useRef<Parser<any>>(new Parser());

  const resetSettings = () => {
    const defaultSettings = { connections: 1, strength: 'Okay', matches: 50, connectionsLimit: 1, noMatchIndex: 0, doNotMatchSameAnswer: false };
    saveAutoSettings(defaultSettings);
    setSettings(defaultSettings);
    setAdvanced(false);
    setCustomQuestions(false);
    alert('Settings have been reset to default. Take a look by seeing advanced settings.');
  }

  const downloadBlob = (blob: Blob, filename: string) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', blobUrl);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    json2csvParser.current = new Parser();
  }

  const handleSubmit = async () => {
    try {
      let nextToken: undefined | string;
      const autoMatchRes = await api.autoMatch(process.env.NODE_ENV !== 'production' ? urls.auto : '/v1/auto-match', auth.orgcode, {
        organization: auth.orgname,
        email: auth.userDetails?.email,
        name: `${auth.userDetails?.given_name} ${auth.userDetails?.family_name}`,
        settings: settings,
        nextToken: nextToken ?? undefined
      }, auth.jwtRef.current);
      if (autoMatchRes.body && typeof autoMatchRes.body === 'string') {
        alert(autoMatchRes.body);
        return;
      }
    } catch (error) {
      console.error(`Auto connect error: ${error}`);
      alert(`Auto connect error: ${error}`);
      return;
    }

    present({
      backdropDismiss: false,
      onDidDismiss: async (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.data === 'confirm') {
          try {
            // do a timeout here, then the after action report
            setTimeout(async () => {
              try {
                // grab members that are still above 0 for currentAutoConnections once it's all done
                // that will tell us which members were not connected or had fewer than the set amount (if they're at the same number as the settings.connections)
                const notConnected = await api.connectionsReport(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', auth.orgcode, auth.jwtRef.current);
                if (notConnected.Items.length === 0) return;
                const csv = json2csvParser.current.parse(notConnected.Items);
                if (notConnected.NextToken) console.log('next token found, create another button');
                const blob = new Blob([csv], { type: 'text/csv' });
                downloadBlob(blob, 'afterConnectionsReport.csv');
              } catch (error) {
                console.error('There was a problem getting your report :( ', error);
              }
            }, 1500); // wait 1.5 seconds 

          }
          catch (error) {
            alert(`An error occurred: ${error}`);
          }
        } else {
          console.log('Auto Connect window closed.');
        }
        dismiss();
        dismissModalInvalidate();
        setIsButtonDisabled(false);
      }
    });
    saveAutoSettings(settings);
    setIsButtonDisabled(true);
  }

  const handleSelectChange = (tag: string, val: number) => {
    setSettings(s => ({ ...s, [tag as keyof AutoMatchTypes]: val }));
  }

  return (
    <div>
      <IonNote>You must have <strong>at least 26 members</strong> to invoke this power <span role="img" aria-label="unicorn">🦄</span>.</IonNote>
      <div style={{
        marginTop: "1rem"
      }}>
        <IonLabel>Number of connections per member:</IonLabel>
        <IonSelect value={settings.connections} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('connections', e.detail.value)}>
          {Array(3).fill(0).map((_, index) => <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>)}
        </IonSelect>
      </div>
      <div>
        <div className='ion-modal-advance-settings'>
          <IonLabel>Using Recommended or Saved Settings (Toggle for advanced options)</IonLabel>
          <IonToggle onIonChange={() => setAdvanced(!advanced)} slot='start' checked={!advanced}></IonToggle>
        </div>
        <div>
          {advanced &&
            <>
              <IonNote>* Recommended settings are based on our experience that tend to yield the most amount of meaningful connections.</IonNote>
              <IonList className='ion-modal-settings-list'>
                <IonLabel>Number of matches (potential connections) per member:</IonLabel>
                <IonSelect value={settings.matches} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('matches', e.detail.value)}>
                  <MatchOptions />
                </IonSelect>
                <IonLabel>Connection strength limit:</IonLabel>
                <IonSelect value={settings.strength} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('strength', e.detail.value)}>
                  {['Excellent', 'Great', 'Okay', 'Low'].map((s, index) => <IonSelectOption key={index} value={s}>{s}</IonSelectOption>)}
                </IonSelect>
                <IonLabel>If matched member has:</IonLabel>
                <IonSelect value={settings.connectionsLimit} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('connectionsLimit', e.detail.value)}>
                  {Array(20).fill(0).map((_, index) => <IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>)}
                </IonSelect>
                <IonLabel>active connection(s), then do not connect</IonLabel>
              </IonList>
            </>
          }
        </div>
        {
          (data?.questions?.M !== undefined) &&
          <div className='ion-modal-advance-settings'>
            <IonLabel>I don't want to match people with the same answer</IonLabel>
            <IonToggle onIonChange={() => {
              setCustomQuestions(!customQuestions);
              setSettings({ ...settings, doNotMatchSameAnswer: !settings.doNotMatchSameAnswer })
            }} checked={customQuestions}></IonToggle>
          </div>
        }
        <div>
          {
            (customQuestions) && <>
              <IonList lines='none' className='ion-modal-settings-list ion-padding-start ion-padding-top'>
                <div>
                  <IonLabel>Select a Custom Question</IonLabel>
                </div>
                <IonNote>
                  This will allow you to create connections with more specific parameters.
                </IonNote>
                <div>
                  <IonNote>
                    E.g.: No matching with people from the same department.
                  </IonNote>
                </div>
                <IonItem className='ion-padding-start'>
                  <IonSelect aria-label="Custom Questions" placeholder="Select a custom question" value={settings.noMatchIndex} onIonChange={(e: CustomEvent<SelectChangeEventDetail>) => handleSelectChange('noMatchIndex', e.detail.value)}>
                    {(data?.questions && data?.questions.M) ? Object.values(data.questions.M).map((item, i) => {
                      if (item.M.type.S === "choice") return <IonSelectOption value={i} key={i}>{item.M.q.S}</IonSelectOption>
                      return <Fragment key={i}></Fragment>
                    }) : <IonNote>There is no custom questions to show</IonNote>}
                  </IonSelect>
                </IonItem>
              </IonList>
            </>
          }
        </div>
        <div>
          {
            toSchedule &&
            <AutoConnectSchedule settings={settings} auth={auth} />
          }
        </div>
        <div className='ion-modal-button-group'>
          <IonButton disabled={isButtonDisabled} color="dark" mode="ios" size="small" onClick={dismissModal}>{isButtonDisabled ? 'Connecting' : 'Cancel'}</IonButton>
          {!toSchedule &&
            <IonButton disabled={isButtonDisabled} mode="ios" size="small" onClick={handleSubmit}>{isButtonDisabled ? 'Connecting' : 'Save & Submit'}</IonButton>
          }
          <IonButton disabled={isButtonDisabled} mode="ios" color="tertiary" size="small" onClick={resetSettings}><IonIcon icon={reloadCircleSharp} /> Recommended Settings</IonButton>
        </div>
      </div>
    </div>
  );
}

export default AutoMatchSettings;