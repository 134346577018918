import { IonButton, IonCol, IonGrid, IonIcon, IonNote, IonRow, IonTitle } from '@ionic/react';
import { pieChartSharp } from 'ionicons/icons';
import { SegChooseBodyTypes } from './Segmentation';

const SegChooseBody = ({ onDismiss }: SegChooseBodyTypes) => {
  return (
    <aside style={{padding: "1.5rem"}}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonTitle size="large">How many segments would you like?</IonTitle>
            <hr />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton className="filter" onClick={() => onDismiss(null, 'cancel')}>
              Just one, please!
            </IonButton>
            <br />
            <hr />
            <IonButton className="filter" onClick={() => onDismiss(null, 'confirm')}>
              Make it 2!
            </IonButton>
            <hr />
          </IonCol>
          <IonCol pushXl='1'>
            <IonIcon style={{fontSize: "7rem"}} icon={pieChartSharp} />
          </IonCol>
        </IonRow>
        <hr /><br />
        <IonRow>
          <IonCol>
            <IonTitle size="large">What is segmentation?</IonTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <blockquote>
              <p>
                <em>Segmentation helps you filter members and connect only those that you have filtered.</em>
              </p>
              <p>
                <em>You can create one segment and connect members within that segment, or create two segments and connect members between them.</em>
              </p>
            </blockquote>
            <br />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonNote>NOTE: At least 15 members must be present in each segment.</IonNote>
          </IonCol>
        </IonRow>
      </IonGrid>

    </aside>
  );
}

export default SegChooseBody;