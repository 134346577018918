import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Pagination, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/thumbs/thumbs.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import '../../style/ionic/Segmentation.css';
import { IonFab, IonFabButton, IonIcon, IonSpinner, useIonModal } from '@ionic/react';
import { settingsSharp, rocketSharp, pieChartSharp } from 'ionicons/icons';
import SegChooseBody from './SegChooseBody';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { MemberReturnTypes } from '../Members';
import FilterBody, { FilterAddedOptionsTypes, FilterTypes } from './FilterBody';
import { useApiContext } from '../../hooks/apiContext';
import { useMutation } from 'react-query';
import { urls } from '../../utilities/urls';
import { useAuthContext } from '../../hooks/authContext';
import { getAutoSettings } from '../../utilities/localSave';
import SegmentTable from './SegmentTable';
import FinishedBody from './SegmentFinishedModal';
import AutoConnectSettings from './AutoConnectSettings';
import SwipeSlide from './SwipeSlide';
import { useHistory } from 'react-router';

export interface SegChooseBodyTypes {
  onDismiss: (data: string | null, role: string) => void
}

interface SegmentationReturnTypes {
  seg1: MemberReturnTypes
  seg2: MemberReturnTypes
}

const Segmentation = () => {

  const userAuth = useAuthContext();
  const history = useHistory();
  const api = useApiContext();
  const app = useAuthContext();

  useLayoutEffect(() => {
    console.log('segs: ', userAuth.auth, userAuth.tier);
    if(!userAuth.auth || userAuth.tier !== 'gold') history.push('/Home');
  }, [])

  useEffect(() => {
    console.log('okay');
  }, [])

  const seg1DefaultState = {
    archetype: [],
    relationship: '',
    interests: [],
    topInterests: [],
    city_current: '',
    education: '',
    gender: '',
    ethnicity: '',
    user: {} as FilterAddedOptionsTypes,
    customAnswers: []
  } as FilterTypes

  const seg2DefaultState = {
    archetype: [],
    relationship: '',
    interests: [],
    topInterests: [],
    city_current: '',
    education: '',
    gender: '',
    ethnicity: '',
    user: {} as FilterAddedOptionsTypes,
    customAnswers: []
  } as FilterTypes

  const [isDoubleSegment, setIsDoubleSegment] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const segment1 = useRef(seg1DefaultState);
  const segment2 = useRef(seg2DefaultState);

  const [present, dismiss] = useIonModal(SegChooseBody, {
    onDismiss: (data: string | null, role: string) => dismiss(data, role),
  });
  
  const [connectSettingsPresent, connectSettingsDismiss] = useIonModal(AutoConnectSettings, {
    dismissModal: () => connectSettingsDismiss()
  });

  const autoConnectSegs = useMutation( async () => {
    return api.autoConnectSegments(
      process.env.NODE_ENV !== 'production' ? urls.segments : '/v1/connect-segments',
      userAuth.orgcode ?? '',
      {adminEmail: userAuth.userDetails?.email, adminOrg: userAuth.orgname},
      isDoubleSegment ? {isDoubleSegment, segment1: segment1.current, segment2: segment2.current, connectionSettings: getAutoSettings()} : {segment1: segment1.current, connectionSettings: getAutoSettings()},
      userAuth.jwtRef.current
    );
  },{
    onSuccess: () => {
      setIsFiltered(false);
      // set conneciton completion modal here
      // alert('The auto connection process is underway. NOTE: Depending on parameters you have set, there may be some members that may not be connected.');
      presentFinish();
    },
    onError: () => alert(`An error has occurred while trying connecting your segment${isDoubleSegment ? 's' : ''}. Please try again later.`)
  });

  const [presentFinish, dismissFinish] = useIonModal(FinishedBody, {
    dismissModal: () => {
      segment1.current = seg1DefaultState;
      segment2.current = seg2DefaultState;
      dismissFinish()
    },
    connectedReturn: autoConnectSegs.data
  });

  const segmentFilters = useMutation( async () => {
    return api.getData(
      process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 
      '', 
      userAuth.orgcode ?? '', 
      isDoubleSegment ? 'doubleSegmentFilter' : 'filteredMembers', 
      isDoubleSegment ? JSON.stringify({segment1: segment1.current, segment2: segment2.current}) : JSON.stringify(segment1.current), 
      userAuth.userDetails?.email,
      userAuth.jwtRef.current
    ).then((res: MemberReturnTypes | SegmentationReturnTypes) => {
      return res;
    })
    .catch(err => { throw new Error(err) });
  },{
    onSuccess: (data: MemberReturnTypes | SegmentationReturnTypes) => {
      setIsFiltered(true);
    },
    onError: () => console.error('An error has occurred.')
  })

  const handleFiltration = async () => {
    await segmentFilters.mutate();
  }

  useEffect(() => {
    (userAuth.auth && userAuth.tier === 'gold') && present({
      onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if(ev.detail.role === 'confirm') setIsDoubleSegment(true);
        else setIsDoubleSegment(false);
      },
      cssClass: ''
    });
    return () => dismiss(null, 'cancel');
  }, []);

  if(!segmentFilters.isLoading && !autoConnectSegs.isLoading) return (
    <>
      <Swiper
        modules={[Pagination, Navigation, Thumbs]}
        spaceBetween={50}
        onSlideChange={() => console.log('slide change')}
        // onSwiper={(sw) => console.log(`Swiper object: ${sw}`)}
        >
        <SwiperSlide>
          { !isFiltered ? 
            <FilterBody 
            dismissModal={() => dismiss(null, 'cancel')} 
            filterChoicesRef={segment1}
            sessionStoreKey="Segment-1"
            app={app}
            />
            :
            <SegmentTable segmentNumber={1} membersLists={!isDoubleSegment ? [segmentFilters.data] as MemberReturnTypes[] : [(segmentFilters.data as SegmentationReturnTypes).seg1]} />
          }
        </SwiperSlide>
        {isDoubleSegment && 
          <SwiperSlide>
            { !isFiltered ? 
              <FilterBody 
              dismissModal={() => dismiss(null, 'cancel')} 
              filterChoicesRef={segment2}
              sessionStoreKey="Segment-2"
              app={app}
              />
              :
              <SegmentTable segmentNumber={2} membersLists={!isDoubleSegment ? [segmentFilters.data] as MemberReturnTypes[] : [(segmentFilters.data as SegmentationReturnTypes).seg2]} />
            }
          </SwiperSlide>
        }
        {isDoubleSegment && <SwipeSlide />}
      </Swiper>
      <IonFab edge horizontal="end" slot="fixed">
        {!isFiltered ?
          <aside>
            <IonFabButton onClick={() => connectSettingsPresent()}><IonIcon icon={settingsSharp} /></IonFabButton>
            <IonFabButton onClick={handleFiltration}><IonIcon icon={pieChartSharp} /><strong>GO!</strong></IonFabButton>
          </aside>
          :
          <IonFabButton onClick={() => autoConnectSegs.mutate()}><IonIcon icon={rocketSharp} /><strong>GO!</strong></IonFabButton>
        }
      </IonFab>
    </>
  );
  else if(segmentFilters.isLoading || autoConnectSegs.isLoading) return (
    <IonSpinner name="lines-sharp" />
  );
  else return (
    <section>Something went wrong. Please try again later.</section>
  );
}

export default Segmentation;