import { IonButton, IonNote, useIonToast } from '@ionic/react';
import { useApiContext } from '../../../hooks/apiContext';
import { urls } from '../../../utilities/urls';
import '../../../style/ionic/Subscription.css';
import { AuthContextTypes } from '../../../AuthProvider';
import { useHistory } from 'react-router';
import { capitalize } from '../../../utilities/text';
import { useState } from 'react';

interface SubscribeTypes {
  dismiss: (data: any, role: string) => void
  toSubcribe: boolean
  subId: string
  cusId: string
  priceId: string
  paymentMethodId: string
  auth: AuthContextTypes
}

const SubscriptionModal = ({ dismiss, toSubcribe, subId, cusId, priceId, paymentMethodId, auth }: SubscribeTypes) => {

  const api = useApiContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSubscription = async (toSubscribe: boolean, subId: string, priceId: string,  paymentMethodId?: string) => {
    setIsButtonDisabled(true);
    try {
      toSubscribe ? 
      // create new subscription/replace with new subscription
      await api.subscribe(process.env.NODE_ENV !== 'production' ? urls.payment : '/v1/payment-control', cusId, subId, 'subscription.create', auth.userDetails?.email, priceId, paymentMethodId, auth.jwtRef.current)
      :
      // update the current subscription
      await api.unsubscribe(process.env.NODE_ENV !== 'production' ? urls.payment : '/v1/payment-control', subId, 'subscription.update', { cancel_at_period_end: true } , auth.jwtRef.current);
      
      dismiss('', 'OK');
    } catch (error) {
      // alert
      console.error('error: ', error);
      alert('Unfortunately, an error occurred. Please try again later');
      dismiss('', 'Cancel');
    }
  }

  return (
    <section className="modal-container">
      {!toSubcribe ?
        <aside>
          <h1>Choosing this option will unsubscribe you from our paid services. Are you sure you want to do that?</h1>
          <IonNote>NOTE: Your subscription will be removed at the end of this last billing period. You will not be charged further.</IonNote>
        </aside>
        :
        <aside>
          <h1>You will be subscribed into the {capitalize(priceId)} tier using your default payment method. Is that okay?</h1>
          <IonNote>NOTE: If you have a subscription set to be removed, it will be removed immediately and replaced by your new subscription. You will be charged beginning today.</IonNote>
        </aside>
      }
      <hr />
      <IonButton disabled={isButtonDisabled} onClick={() => handleSubscription(toSubcribe, subId, priceId, paymentMethodId)} >Yes</IonButton>
      <IonButton onClick={() => dismiss('', 'Cancel')}>No</IonButton>
    </section>
  )
}

export default SubscriptionModal;