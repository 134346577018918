import { IonButton, useIonToast } from '@ionic/react';
import { AuthContextTypes } from '../../../AuthProvider';
import { useApiContext } from '../../../hooks/apiContext';
import { useAuthContext } from '../../../hooks/authContext';
import '../../../style/ionic/Subscription.css';
import { urls } from '../../../utilities/urls';

interface AccountModalTypes {
  dismiss: () => void
  cusId: string
  auth: AuthContextTypes
}

const AccountModal = ({ dismiss, cusId, auth }: AccountModalTypes) => {
  
  const api = useApiContext();
  const [presentToast] = useIonToast();

  const handleAccount = async () => {
    try {

      await api.deleteAccount(process.env.NODE_ENV !== 'production' ? urls.payment : '/v1/payment-control', cusId, 'customer.delete', auth.jwtRef.current);

      presentToast({
        message: 'Your account has been successfully deleted. Hopefully, we\'ll see you again 😭.',
        duration: 4000,
        position: 'top',
        onDidDismiss: () => {
          auth.logout && auth.logout();
          localStorage.clear();
          window.location.reload();
        }
      });
      dismiss();
    } catch (error) {
      // alert
      console.error('error: ', error);
      alert('Unfortunately, an error occurred. Please try again later');
      dismiss();
    }
  }
  return (
    <section className="modal-container">
      <h1>Are you sure you would like to delete your Rivet account?</h1>
      <p><span aria-label="warning" role="img">⚠️</span> Doing so will remove all of your <strong>members</strong>, <strong>data</strong>, and <strong>subscription</strong> immediately. You will also be signed out.</p>
      <IonButton  onClick={handleAccount}>Yes</IonButton>
      <IonButton  onClick={dismiss}>No</IonButton>
    </section>
  );
}

export default AccountModal;