import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonButton,
} from '@ionic/react';
import LogoutButton from './LogoutButton';
import { useAuthContext } from '../hooks/authContext';

import { useHistory, useLocation } from 'react-router-dom';
import rivetLogoDark from '../resources/02_Logo_White.svg';
import rivetLogoLight from '../resources/01_Logo_Black.svg';
import { peopleCircleOutline, peopleCircleSharp, pieChartSharp, pieChartOutline, rocketSharp, rocketOutline, analyticsSharp, analyticsOutline, logInSharp } from 'ionicons/icons';
import './Menu.css';
import { useLayoutEffect } from 'react';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Launch!',
    url: '/Home',
    iosIcon: rocketOutline,
    mdIcon: rocketSharp
  },
  {
    title: 'Members',
    url: '/Members',
    iosIcon: peopleCircleOutline,
    mdIcon: peopleCircleSharp
  },
  {
    title: 'Analytics',
    url: '/Analytics',
    iosIcon: analyticsOutline,
    mdIcon: analyticsSharp
  },
  {
    title: 'Segment ß',
    url: '/Segmentation',
    iosIcon: pieChartOutline,
    mdIcon: pieChartSharp
  },
  // {
  //   title: 'Account',
  //   url: '/Account',
  //   iosIcon: personOutline,
  //   mdIcon: personSharp
  // }
];

const Menu: React.FC = () => {
  const userAuth = useAuthContext();
  const location = useLocation();
  const history = useHistory();
  const auth = useAuthContext();

  useLayoutEffect(() => {
    const [sub] = window.location.hostname.split('.');
    if (window.location.hostname.includes('likeminded.ai')) window.location.replace(`https://${sub}.workrivet.com`);
  }, [])

  const routeToPage = (r: string) => {
    history.replace(r);
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent className="satoshi">
        <IonList id="inbox-list">
          <picture>
            <source srcSet={rivetLogoDark} media="(prefers-color-scheme:dark)" />
            <img className="menu-logo" src={rivetLogoLight} alt="Rivet" />
          </picture>
          <hr />
          <IonNote>Spark new connections. Create strong cultures.</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem disabled={['Analytics', 'Segment ß'].includes(appPage.title) && auth.tier !== 'gold'} className={location.pathname === appPage.url ? 'selected' : ''} button onClick={() => {
                  sessionStorage.setItem("last_path", appPage.url)
                  routeToPage(appPage.url);
                }} lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem>
            {userAuth.auth ? <LogoutButton /> : <IonButton color="dark" fill="clear" routerLink="/Home"><IonIcon icon={logInSharp} /> &nbsp; Login</IonButton>}
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
