import { FocusEvent, useRef, useState } from 'react';
import { IonList, IonItem, IonLabel, IonInput, IonButton, IonModal, IonToast, IonTitle, IonNote } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useCognitoContext } from '../hooks/cognitoContext';
import { loginBtn, loginContainer, loginContainerMobile } from '../style/ts/LoginCSS';
import '../style/ionic/Login.css';

interface NewPasswordTypes {
  email: string | number | null | undefined
  verificationCode: string | number | null | undefined
  newPassword: string | number | null | undefined
  newPasswordVerify: string | number | null | undefined
}

const NewPassword = () => {

  const history = useHistory();
  const cognito = useCognitoContext();
  const [showDialog, setShowDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const credentials = useRef({} as NewPasswordTypes);

  const handleInput = (e: FocusEvent<HTMLIonInputElement> | undefined) => {
    e?.preventDefault();
    if(e && credentials.current) credentials.current[`${e?.target.name}` as keyof NewPasswordTypes] = (e?.target.value as string).trim();
  }

  const handlePasswordRequest = async () => {
    await cognito.forgotPassword((credentials.current.email as string).trim().toLowerCase())
      .then(res => {
        console.log('res: ', res);
        setShowDialog(true);
      }).catch(err => {
        console.error('err: ', err);
        alert('Sorry but we don\'t have that email in our database.')
      });
  }

  const handleNewPassword = () => {
    if(credentials.current.newPassword !== credentials.current.newPasswordVerify) {
      alert('Sorry but the passwords aren\'t matching...please make sure the new password matches the verify password.');
      return;
    }
    cognito.confirmPassword(credentials.current)
      .then(res => {
        console.log('new: ', res);
        setShowDialog(false);
        setShowToast(true);
      }).catch((err: Error) => {
        console.error('new err: ', err);
        alert(err.message);
      });
  }

  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleToastClose = () => {
    setShowToast(false);
    history.push('/Login');
  }

  return (
    <section style={(window.innerWidth < 600) ? loginContainerMobile : loginContainer} className="satoshi container">
      <h1>Forgot your password? <em>No worries!</em></h1>
      <br />
      <IonList mode="ios">
        <IonItem>
          <IonLabel mode="ios" position="stacked">What's your email address?</IonLabel>
          <IonInput name="email" type="email" placeholder="Email" onBlur={handleInput} />
        </IonItem>
        <IonButton style={loginBtn}  onClick={handlePasswordRequest} type="button">Request New Password</IonButton>
      </IonList>
      {showDialog && (
        <IonModal mode="ios" isOpen={showDialog} onDidDismiss={handleDialogClose} backdropDismiss={false}>
          <IonTitle>Please enter your confirmation credentials below:</IonTitle>
          <p style={{padding: '1.2rem'}}>* You should have recieved an email with your verification code. <strong>Please check your email.</strong></p>
          <IonNote style={{padding: '1.2rem', backgroundColor: 'yellow', fontWeight: 'bold'}}>The password must, at minimum, be 8 characters, and must have at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.</IonNote>
          <IonList mode="ios">
            <IonItem>
              <IonLabel>Verification Code</IonLabel>
              <IonInput name="verificationCode" type="password" placeholder="Verification Code" onBlur={handleInput} />
            </IonItem>
            <IonItem>
              <IonLabel>New Password</IonLabel>
              <IonInput name="newPassword" type="password" placeholder="New Password" onBlur={handleInput} />
            </IonItem>
            <IonItem>
              <IonLabel>Verify Password</IonLabel>
              <IonInput name="newPasswordVerify" type="password" placeholder="Verify Password" onBlur={handleInput} />
            </IonItem>
              <IonButton onClick={handleNewPassword} type="button">Submit</IonButton>
          </IonList>
        </IonModal>
      )}
      {showToast && (
        <IonToast
          isOpen={showToast}
          message="New password is set!"
          onDidDismiss={handleToastClose}
          duration={1000}
        />
      )}
    </section>
  )
}

export default NewPassword;