import { useState, useEffect, useRef } from 'react';
import { IonButton, IonIcon,  IonNote } from '@ionic/react';
import MatchComponent from './MatchComponent';
import { chevronBackOutline } from 'ionicons/icons';
import { useQuery, useQueryClient } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import { MemberTypes } from './Members';
import { HexacoTypes, MbtiTypes, DynamoListStringTypes, DynamoNumberTypes } from './Member';
import { urls } from '../utilities/urls';
import { sectionContainer, pageTitle, subContainer, tabs, selectedTab } from '../style/ts/MemberMatchesCSS';
import '../style/ionic/MemberMatches.css';
import { StringType } from './interfaces/questions-types';

export interface MemberMatchTypes extends MemberTypes {
  hexaco: HexacoTypes
  mbti: MbtiTypes
  current_city: DynamoListStringTypes
  points: number
  activeConnections: DynamoNumberTypes
  message?: string
}

export interface CurrentUserTypes {
  user1Id?: string
  name?: string,
  last?: string,
  email?: string
  cIds?: StringType[]
}

const MemberMatches = () => {
  const api = useApiContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const userAuth = useAuthContext();
  const { state: { user1Id, name, last, email, cIds } = {} } = useLocation<CurrentUserTypes>();
  const [matchType, setMatchType] = useState('kindred');
  // const matchTypeArray = useRef(['kindred', 'business', 'activity', 'growth']);
  const matchTypeArray = useRef(['kindred', 'growth']);

  const handleMatchTypeChoice = (typeChoice: string) => {
    setMatchType(typeChoice);
  }

  const reset = () => {
    matchTypeArray.current.forEach(p => queryClient.resetQueries(`matchedMembers-${p}`)
      .then(res => res).catch(console.error));
  }

  useEffect(() => {
    return () => {
      reset();
    }
  }, []);

  const { refetch, isIdle, isError, error, data } = useQuery<MemberMatchTypes[] | string, Error>([`matchedMembers-${matchType}`, user1Id], async () => {
    return await api.calculateMatches(process.env.NODE_ENV !== 'production' ? urls.match : '/v1/calculate-match-user', user1Id, matchType, userAuth.orgcode ?? '', userAuth.settings?.matchesToShow, userAuth.settings?.connectionsLimit, userAuth.jwtRef.current)
      .then(res => res)
      .catch(err => { throw new Error(err) });
  }, {
    // enabled: false, // don't run at the outset
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!user1Id
  });

  return (
    <section>
      <IonButton onClick={() => history.goBack()} fill="clear">
        <IonIcon color="dark" slot="icon-only" icon={chevronBackOutline} />
      </IonButton>
      <section style={sectionContainer}>
        <h3 style={pageTitle}>Check out <span style={{ textTransform: "capitalize" }}>{name}</span>'s matches below!</h3>
        <aside style={subContainer}>
          <IonNote>
            Matches are based on intent, personality, life stage, life experience, and future aspirations.
          </IonNote>
        </aside>
        <aside style={subContainer}>
          <nav style={{ paddingTop: '1.5rem' }}>
            {matchTypeArray.current.map((i, index) => {
              return <a key={index} style={(matchType === i) ? selectedTab : tabs} onClick={() => handleMatchTypeChoice(i)}>{i} </a>
            })}
          </nav>
          <aside style={subContainer}>
            {(() => {
              switch (matchType) {
                case 'kindred':
                  return <IonNote>A high number in the "Kindred" category indicates your matches approach life from similar perspectives.</IonNote>
                case 'growth':
                  return <IonNote>A high number in the "Growth" category indicates your matches approach life from different perspectives.</IonNote>
                default:
                  break;
              }
            })()}
          </aside>
          <aside style={{ paddingTop: "1.5rem" }}>
            {(() => {
              if (isError) return <IonNote style={{ marginLeft: '1.5rem' }}>An error occurred: {error?.message}</IonNote>
              else if (typeof data === 'string') return <IonNote style={{ marginLeft: '1.5rem' }}>{data}</IonNote>
              else if (data && data[0] && data[0].message) return <IonNote style={{ marginLeft: '1.5rem' }}>{data[0].message}</IonNote>
              else if (data && data.length <= 0) return <IonNote style={{ marginLeft: '1.5rem' }}>Looks like there aren't any matches at the moment.</IonNote>
              else return <MatchComponent refetch={refetch} isIdle={isIdle} data={data} user={{ user1Id, name, last, email, cIds }} matchType={matchType} />
            })()}
          </aside>
        </aside>
      </section>
    </section>
  );
}

export default MemberMatches;