import { RadioGroupChangeEventDetail } from '@ionic/core';
import { CheckboxChangeEventDetail, IonAccordion, IonAccordionGroup, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonGrid, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, IonTitle, SelectChangeEventDetail } from '@ionic/react';
import { Fragment, MutableRefObject, useRef } from 'react';
import { IonInputCustomEvent, IonCheckboxCustomEvent } from '@ionic/core/dist/types/components';
import '../../style/ionic/FilterBody.css';
import InterestsAccordion from '../InterestsAccordion';
import randomInt from '../../utilities/random-int';
import addedOptions from '../../data/added.json';
import { capitalize } from '../../utilities/text';
import { QuestionAnswerTypes } from '../interfaces/questions-types';
import CustomQuestions from './CustomQuestions';
import { AuthContextTypes } from '../../AuthProvider';

export interface FilterAddedOptionsTypes {
  buddy: string
  years: number
  mentor: string
  department: string
  intern: string
}

export interface FilterTypes {
  archetype: string[]
  relationship: string
  interests: string[]
  topInterests: string[]
  city_current: string
  education: string
  gender: string
  ethnicity: string
  user: FilterAddedOptionsTypes
  customAnswers: QuestionAnswerTypes[]
}

interface FilterBodyTypes {
  dismissModal: (data: any, role: string) => void
  handleFiltration?: () => void
  filterChoicesRef: MutableRefObject<FilterTypes>
  sessionStoreKey: string
  app: AuthContextTypes
}

const archetypeRadios = [
  'Advocate', 'Caregiver', 'Dreamer', 'Explorer',
  'Individualist', 'Organizer', 'Promoter', 'Rock',
  'Spark', 'Storyteller', 'Thinker', 'Traditionalist'
];

const relationshipRadios = [
  'Single', 'In a relationship', 'Engaged', 'Married',
  'Separated', 'Divorced', 'Widowed', 'Other...'
];

const genderRadios = [
  'Male', 'Female', 'Non-binary/third gender'
];

const ethnicityRadios = [
  'Hispanic or Latin@/x',
  'Native American or Alaskan Native',
  'Asian',
  'African American, or of African origin',
  'Native Hawaiian or Other Pacific Islander',
  'White or of European origin',
  'I prefer not to answer'
];

const educationRadios = [
  "Some High School", "GED or High School Equivalent",
  "High School Diploma", "Some College", "Bachelor's Degree",
  "Master's Degree", "Ph.D or higher", "Trade School",
  "Prefer not to say", "Other..."
];

const FilterBody = ({ dismissModal, handleFiltration, filterChoicesRef, sessionStoreKey, app }: FilterBodyTypes) => {

  const cityRef = useRef('');
  const stateRef = useRef('');

  const filterify = () => {
    handleFiltration && handleFiltration();
    dismissModal('', ''); // FIX: This causes a React cannot perform a state update on an unmounted component error
  }

  const customAdd = (sel: string) => {
    filterChoicesRef.current.customAnswers.push({[`${(sel as string).split('-')[0]}`]: (sel as string).split('-')[1]});
  }

  const handleSelection = (selection: string | number, tag: string, interestOrType?: string) => {
    console.log('filter handlez, ', tag);
    switch (tag) {
      case 'archetype':
        if(filterChoicesRef.current.archetype?.some(arch => arch === selection)) filterChoicesRef.current.archetype = filterChoicesRef.current.archetype.filter(arch => arch !== selection);
        else filterChoicesRef.current.archetype?.push(selection as string);
        break;
      case 'relationship':
      case 'gender':
      case 'ethnicity':
      case 'education':
        (filterChoicesRef.current[tag as keyof FilterTypes] as string) = selection as string;
        break;
      case 'years':
        (filterChoicesRef.current.user[tag as keyof FilterAddedOptionsTypes] as number) = selection as number;
        break;
      case 'buddy':
      case 'mentor':
      case 'department':
      case 'intern':
        (filterChoicesRef.current.user[tag as keyof FilterAddedOptionsTypes] as string) = selection as string;
        break;
      case 'interests':
        // creates a compound string ex: fitness_choice-running
        if(filterChoicesRef.current.interests?.some(int => int === `${interestOrType}-${selection}`)) filterChoicesRef.current.interests = filterChoicesRef.current.interests?.filter(int => int !== `${interestOrType}-${selection}`);
        else filterChoicesRef.current.interests = [...filterChoicesRef.current.interests, `${interestOrType}-${selection}`];
        break;
      case 'topInterests':
        if(filterChoicesRef.current.topInterests?.some(int => int === `${selection}-${5}`)) {
          // get index, then delete
          const intIndex = filterChoicesRef.current.topInterests.indexOf(`${selection}-${5}`);
          filterChoicesRef.current.topInterests.splice(intIndex, 1);
        }
        else filterChoicesRef.current.topInterests.push(`${selection}-${5}`);
        break;
      case 'city':
        cityRef.current = selection as string;
        if(cityRef.current && stateRef.current) filterChoicesRef.current.city_current = `${cityRef.current}, ${stateRef.current}`;
        break;
      case 'state':
        stateRef.current = selection as string;
        if(cityRef.current && stateRef.current) filterChoicesRef.current.city_current = `${cityRef.current}, ${stateRef.current}`;
        break;
      case 'customAnswers':
        // check type, then add/remove/replace as necessary
        if(interestOrType === 'open') {
          if(Object.entries(filterChoicesRef.current.customAnswers).some(c => Object.entries(c[1])[0][0] === (selection as string).split('-')[0])) {
            const intIndex = Object.entries(filterChoicesRef.current.customAnswers).findIndex(c => Object.entries(c[1])[0][0] === (selection as string).split('-')[0]);
            filterChoicesRef.current.customAnswers.splice(intIndex, 1);
            if(interestOrType === 'open') filterChoicesRef.current.customAnswers.push({[`${(selection as string).split('-')[0]}`]: (selection as string).split('-')[1]});
          }
          else filterChoicesRef.current.customAnswers.push({[`${(selection as string).split('-')[0]}`]: (selection as string).split('-')[1]});
        } else if (interestOrType === 'choice') {
          // remove if same, add if not same
          if(Object.entries(filterChoicesRef.current.customAnswers).some(c => {
            if(Object.entries(c[1])[0][0] !== (selection as string).split('-')[0]) return false;
            else if((Object.entries(c[1])[0][1] || Object.entries(c[1])[0][1].length > 0) || Object.entries(c[1])[0][1].includes((selection as string).split('-')[1])) {
              return true;
            }
          })) {
            Object.entries(filterChoicesRef.current.customAnswers).forEach(c => {
              if (typeof Object.entries(c[1])[0][1] === 'string') {} // pass
              else {
                if(Object.entries(c[1])[0][1].includes((selection as string).split('-')[1])) {
                  const removeIndex = (Object.entries(c[1])[0][1] as string[]).findIndex( x => x === (selection as string).split('-')[1]);
                  (Object.entries(c[1])[0][1] as string[]).splice(removeIndex, 1);
                } else {
                  (Object.entries(c[1])[0][1] as string[]).push((selection as string).split('-')[1]);
                }
              }
            });

          } else {
            filterChoicesRef.current.customAnswers.push({[`${(selection as string).split('-')[0]}`]: [(selection as string).split('-')[1]]})
          }
        }
        
        // console.log(JSON.stringify(filterChoicesRef.current));
        break;
      default:
        console.info('unknown tag');
    }
    sessionStorage.setItem(sessionStoreKey, JSON.stringify(filterChoicesRef.current));
  }
  return (
    <IonCard style={{height: "100%"}}>
      <IonCardHeader>
        { sessionStoreKey !== 'filterState' ?
          <Fragment>
            <IonCardTitle>{sessionStoreKey.split('-').join(' ')}</IonCardTitle>
            <p>Press <strong>GO!</strong> to filter and create your segment(s)</p>
          </Fragment>
          :
          <IonCardTitle>Select Filters</IonCardTitle>
        }
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList>
                <IonAccordionGroup>
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>Archetype</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      {archetypeRadios.map((a, index) => (
                        <IonItem key={`${index}-${randomInt()}`}>
                          <IonCheckbox checked={filterChoicesRef.current.archetype.includes(`the ${a.toLowerCase()}`)} slot="start" value={`the ${a.toLowerCase()}`} onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => handleSelection(e.detail.value, 'archetype')} />
                        <IonLabel>{a}</IonLabel>
                      </IonItem>
                      ))} 
                    </aside>
                  </IonAccordion>
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>Relationship</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      <IonRadioGroup value={filterChoicesRef.current.relationship} onIonChange={(e: CustomEvent<RadioGroupChangeEventDetail<any>>) => handleSelection(e.detail.value, 'relationship')}>
                        {relationshipRadios.map((a, index) => (
                          <IonItem key={`${index}-${randomInt()}`}>
                            <IonLabel>{a}</IonLabel>
                            <IonRadio slot="start" value={a.toLowerCase()} />
                          </IonItem>
                        ))} 
                      </IonRadioGroup>
                    </aside>
                  </IonAccordion>
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>Gender</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      <IonRadioGroup value={filterChoicesRef.current.gender} onIonChange={(e: CustomEvent<RadioGroupChangeEventDetail<any>>) => handleSelection(e.detail.value, 'gender')}>
                        {genderRadios.map((a, index) => (
                          <IonItem key={`${index}-${randomInt()}`}>
                            <IonLabel>{a}</IonLabel>
                            <IonRadio slot="start" value={a.toLowerCase()} />
                          </IonItem>
                        ))} 
                      </IonRadioGroup>
                    </aside>
                  </IonAccordion>
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>Ethnicity</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      <IonRadioGroup value={filterChoicesRef.current.ethnicity} onIonChange={(e: CustomEvent<RadioGroupChangeEventDetail<any>>) => handleSelection(e.detail.value, 'ethnicity')}>
                        {ethnicityRadios.map((a, index) => (
                          <IonItem key={`${index}-${randomInt()}`}>
                            <IonLabel>{a}</IonLabel>
                            <IonRadio slot="start" value={a.toLowerCase()} />
                          </IonItem>
                        ))} 
                      </IonRadioGroup>
                    </aside>
                  </IonAccordion>
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>Education</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      <IonRadioGroup value={filterChoicesRef.current.education} onIonChange={(e: CustomEvent<RadioGroupChangeEventDetail<any>>) => handleSelection(e.detail.value, 'education')}>
                        {educationRadios.map((a, index) => (
                          <IonItem key={`${index}-${randomInt()}`}>
                            <IonLabel>{a}</IonLabel>
                            <IonRadio slot="start" value={a.toLowerCase()} />
                          </IonItem>
                        ))} 
                      </IonRadioGroup>
                    </aside>
                  </IonAccordion>
                  {/* Added Options */}
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>Added Options</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      {addedOptions.map(({name, options}, index) => {
                        if (['buddy', 'mentor', 'intern'].includes(name)) {
                          {return (
                            <aside key={`${index}-${randomInt()}`}>
                              <IonItem slot="header">
                                <IonLabel>{capitalize(name)}</IonLabel>
                              </IonItem>
                              <IonRadioGroup value={filterChoicesRef.current.user[name as keyof FilterAddedOptionsTypes]} onIonChange={(e: CustomEvent<RadioGroupChangeEventDetail<any>>) => handleSelection(e.detail.value, name)}>
                                {options.map((a, index) => (
                                  <IonItem key={`${index}-${randomInt()}`}>
                                    <IonLabel>{a}</IonLabel>
                                    <IonRadio slot="start" value={typeof a === 'string' ? a.toLowerCase() : a} />
                                  </IonItem>
                                ))} 
                              </IonRadioGroup>
                            </aside>
                            )}
                        } else if(name === 'years') {
                          {return (
                            <aside key={`${index}-${randomInt()}`}>
                              <IonItem slot="header">
                                <IonLabel>{capitalize(name)}</IonLabel>
                              </IonItem>
                              <IonSelect style={{width: "6rem"}} value={filterChoicesRef.current.user[name as keyof FilterAddedOptionsTypes] ?? 0} onIonChange={(e: CustomEvent<SelectChangeEventDetail<any>>) => handleSelection(e.detail.value, name)}>
                                {options.map((o, index) => (
                                  <IonSelectOption key={`${index}-${randomInt()}`} value={o}>
                                    {o === 10 ? '10+' : o}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </aside>
                          )}
                        } else if(name === 'department') {
                          {return (
                            <aside key={`${index}-${randomInt()}`}>
                              <IonItem slot="header">
                                <IonLabel>{capitalize(name)}</IonLabel>
                              </IonItem>
                              <IonInput placeholder='i.e. Finance' value={filterChoicesRef.current.user[name as keyof FilterAddedOptionsTypes]} onIonBlur={(e: IonInputCustomEvent<FocusEvent>) => handleSelection(e.target.value as string, name)} />
                            </aside>
                          )}
                        }
                      })}
                      
                    </aside>
                  </IonAccordion>
                  <InterestsAccordion handleSelection={handleSelection} filterChoicesRef={filterChoicesRef} />
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonLabel>City</IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      <IonItem>
                        <IonInput style={{textTransform: "capitalize"}} value={filterChoicesRef.current.city_current.split(',')[0]} placeholder="Milwaukee" 
                          onIonBlur={(e: IonInputCustomEvent<FocusEvent>) => handleSelection((e.target.value as string).toLowerCase(), 'city')}
                        />
                        <IonInput style={{textTransform: "capitalize"}} value={filterChoicesRef.current.city_current.split(',')[1]?.toUpperCase()} className="state" placeholder="WI" 
                          onIonBlur={(e: IonInputCustomEvent<FocusEvent>) => handleSelection((e.target.value as string).toLowerCase(), 'state')}
                        />
                      </IonItem>
                    </aside>
                  </IonAccordion>
                <CustomQuestions filterChoicesRef={filterChoicesRef} handleSelection={handleSelection} oc={app.orgcode} jwtString={app.jwtRef.current} />
                </IonAccordionGroup>
              </IonList>
              {handleFiltration &&
                <aside style={{textAlign: "center"}}>
                  <IonButton size="small" onClick={filterify}>Apply</IonButton>
                  <IonButton fill="clear" color="dark" onClick={() => dismissModal('', 'cancel')}>Cancel</IonButton>
                </aside>
              }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
}

export default FilterBody;