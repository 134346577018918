// Self contain: styling, types, and markup
import { useState, useImperativeHandle, Ref, forwardRef } from 'react';
import { useIonAlert, IonLoading } from '@ionic/react';
import ConnectionSSContent from './ConnectionSSContent';
import { useMutation, useQueryClient } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { MemberConnectionTypes, MembersMapTypes } from './MemberConnections';
import { urls } from '../utilities/urls';
import { side } from './Sidesheet';
import '../style/ionic/Sidesheet.css';

interface ConnectionSidesheetTypes {
  connectionData: MemberConnectionTypes
}

export interface ConnectionSideSheetHandle {
  handleShow: () => void
}

interface SideSheetMutationTypes {
  id: string
  members: MembersMapTypes
  matchDateTime: string
}

const ConnectionSidesheet = forwardRef(({ connectionData }: ConnectionSidesheetTypes, ref: Ref<ConnectionSideSheetHandle>) => {
  const api = useApiContext();
  const queryClient = useQueryClient();
  const authContext = useAuthContext();
  const [present] = useIonAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [showSide, setShowSide] = useState(false);

  const mutation = useMutation(async ({ id, members, matchDateTime }: SideSheetMutationTypes) => {
    await api.removeConnection(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 'connection', id, members, matchDateTime, authContext.jwtRef.current).then(res => res).catch(err => { throw new Error(err) });
  },
    {
      onSuccess: () => {
        present({
          header: 'Connection Deleted!',
          buttons: [
            'Cancel',
            {
              text: 'Ok', handler: (d) => queryClient.resetQueries(`connections`)
                .then(res => console.info('reset res: ', res)).catch(console.error)
            },
          ],
          onDidDismiss: () => { queryClient.resetQueries(`connections`) }
        });
      },
      onError: () => {
        present({
          header: 'Connection Deletion Unsuccessful',
          message: 'It looks like there was an error. Please try again later.',
          cssClass: 'alert-unsuccessful'
        })
      },
      onSettled: () => setIsLoading(false)
    })

  const handleConnectionCommand = (id: string | undefined, members: MembersMapTypes, matchDateTime: string | undefined) => {
    mutation.mutate({ id: id ?? '', members: members, matchDateTime: matchDateTime ?? '' });
    setIsLoading(true);
  }

  const toggleSidesheet = () => {
    setShowSide(true);
  }

  useImperativeHandle(ref, () => {
    return {
      handleShow: toggleSidesheet
    };
  });

  if (isLoading) return <IonLoading isOpen />

  return (
    <aside style={showSide ? (window.innerWidth > 600) ? { ...side, width: '32vw' } : { ...side, width: '80vw' } : side}>
      <ConnectionSSContent handleConnectionCommand={handleConnectionCommand} connectionData={connectionData} />
    </aside>
  );
});

export default ConnectionSidesheet;