import React, { useState, MutableRefObject } from 'react';
import { IonSearchbar, IonIcon, SearchbarChangeEventDetail } from '@ionic/react';
import { IonSearchbarCustomEvent } from '@ionic/core/dist/types/components';
import { informationCircle } from 'ionicons/icons';
import { capitalize } from '../utilities/text';

interface SearchTypes {
  refetch: () => void
  searchParam: MutableRefObject<string | undefined>
}

const Search = ({ refetch, searchParam }: SearchTypes) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleInput = (search: string | undefined) => {
    searchParam.current = search?.toLocaleLowerCase().trim();
    refetch();
  }

  return (
    <section>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <IonSearchbar
          placeholder="Search an user..."
          value={`${searchParam.current ? capitalize(searchParam.current) : ''}`}
          debounce={600}
          onIonChange={(e: IonSearchbarCustomEvent<SearchbarChangeEventDetail>) => handleInput(e.detail.value)}
        />
        <div
          style={{ marginLeft: '8px', cursor: 'pointer', position: 'relative' }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <IonIcon icon={informationCircle} style={{ fontSize: '24px', color: '#3880ff' }} />
          {showTooltip && (
            <div style={{
              position: 'absolute',
              bottom: '-40px',
              right: '0',
              background: 'rgba(0,0,0,0.8)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              zIndex: 1000
            }}>
              Search by first name or last name only.
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Search;