import { Fragment } from 'react';
import { IonButton, useIonAlert } from '@ionic/react';
import Avatar from 'react-avatar';
import { userName, subContainer, capitalizeText } from '../style/ts/SidesheetContentCSS';
import { MemberConnectionTypes, MembersMapTypes } from './MemberConnections';
import '../style/ionic/SidesheetContent.css';
import { useMutation } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { urls } from '../utilities/urls';
import { useAuthContext } from '../hooks/authContext';

interface ConnectionSSContentTypes {
  handleConnectionCommand: (id: string | undefined, members: MembersMapTypes, matchDateTime: string | undefined) => void
  connectionData: MemberConnectionTypes
}

const ConnectionSSContent = ({  handleConnectionCommand, connectionData }: ConnectionSSContentTypes) => {
  
  const api = useApiContext();
  const authContext = useAuthContext();
  const [present] = useIonAlert();

  const handleConnection = () => {
    handleConnectionCommand && handleConnectionCommand(connectionData.connectionItems.id.S, connectionData.connectionItems.members, connectionData.connectionItems.matchDateTime.S);
  }

  const mutation = useMutation( async () => {
    await api.sendConnectionReminder(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', connectionData.connectionItems.id.S,connectionData.connectionItems.members, connectionData.connectionItems.matchDateTime.S, connectionData.connectionItems.type.S, authContext.jwtRef.current).then(res => res).catch(err => {throw new Error(err)});
  }, 
  {
    onSuccess: () => {
      present({
        header: 'Reminder Sent!',
        buttons: [
          'Sweet!'
        ]
      });
    },
    onError: () => {
      present({
        header: 'Reminder Unsuccessful :(',
        message: 'It looks like there was an error. Please try again later.',
        cssClass: 'alert-unsuccessful'
      })
    }
  });

  return (
    <Fragment>
      <h2 className="darkside">Connection</h2>
      <aside style={subContainer}>
        <Avatar value={`${connectionData.connectionItems?.connectionStrength.N}%`} round size="50"/>
        <h4 className="darkside" style={userName}>{connectionData.connectionDetails?.user.M.fname.S} {connectionData.connectionDetails?.user.M.lname.S}</h4>
      </aside>
      <aside style={subContainer}>
        <IonButton className="connect" onClick={() => mutation.mutate()}>Connection Reminder</IonButton>
        <br />
        <IonButton className="connect" onClick={handleConnection}>- Remove Connection</IonButton>
      </aside>
    </Fragment>
  );
}

export default ConnectionSSContent;