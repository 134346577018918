import { createContext } from 'react';
import { keys } from '../utilities/urls';
import { StringType } from '../components/interfaces/questions-types';

export interface HttpHeaders {
  [key: string]: string;
}

export class GwApiService {

  _headers: HttpHeaders;

  constructor() {
    console.log('service constructing...');
    this._headers = { 'Content-Type': 'application/json' };
    if (process.env.NODE_ENV !== 'production') this._headers['X-API-KEY'] = keys.akey;
  }

  handleStatus = (status: number) => {
    switch (status) {
      case 401:
        console.error('Expired token/Unauthorized');
        alert('Your session has expired/Unauthorized. Press Okay to refresh.');
        window.location.reload();
        break;
      case 500:
      case 501:
      case 502:
        console.error('Server error');
        alert('There was a server error. Please try again later.');
        throw 'Server error';
      default:
        return true;
    }
  }

  hasUndefined = (id?: string | 'NULL', jwt?: string | 'NULL', email?: string | 'NULL') => {
    const hasUn = [{ id }, { jwt }, { email }].some((i, index, arr) => { if (!i) { console.log(`User ${Object.keys(arr[index])[0]} not provided.`); return true; } });
    return hasUn;
  }

  // where parameter is condition where field must be met, i.e. WHERE orgcode = '1234'
  getData = async (url = '', nextToken = '', where = '', getType = '', getSearchParam = '', email = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: getType,
      nextToken: nextToken, // for pagination
      where: where,
      getSearchParam: getSearchParam,
      email
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getConnections = async (url = '', type = '', uId = '', connectionIds: StringType[] | undefined, jwt = '') => {
    if (this.hasUndefined(uId, jwt) || connectionIds?.length === 0) throw new Error('Missing id or jwt');
    const payload = { uId, connectionIds };
    this._headers['Authorization'] = `${jwt}`;

    const params = new URLSearchParams({
      type
    })

    return await fetch(url + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getMember = async (url = '', memberId = '', alreadyViewed = 'false', email = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, email)) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: 'userSpecific',
      memberId: memberId,
      alreadyViewed: alreadyViewed,
      email: email
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => {
      if (res.status === 401) throw 'Unauthorized';
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getOrgcode = async (url = '', username = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: 'orgcode',
      username: username,
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  getEnterpriseOrgs = async (url = '', username = '', email = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, email)) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: 'enterprise_orgs',
      username: username,
      email
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  calculateMatches = async (url = '', memberId = '', matchType = 'kindred', orgcode = '', matchesLimit: number | undefined, connectionsLimit: number | undefined, jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    if (!orgcode) throw new Error('No orgcode found');
    const params = new URLSearchParams({
      id: memberId,
      matchType: matchType,
      orgcode: orgcode,
      matchesLimit: matchesLimit?.toString() ?? '50',
      connectionsLimit: connectionsLimit?.toString() ?? '3',
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(`${url}?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  makeConnection = async (url = '', connectionData = {}, orgcode = '', matchType = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    if (!orgcode) throw new Error('No orgcode found');
    const params = new URLSearchParams({
      orgcode: orgcode,
      matchType: matchType
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(connectionData)
    }).then(res => {
      if (res.status >= 300) throw new Error('Connection error');
      else return res.json()
    })
      .catch(err => {
        console.log('connection error:', err);
        throw new Error(err)
      });
  }

  autoMatch = async (url = '', orgcode = '', admin = {}, jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        orgcode: orgcode,
        admin: admin
      })
    }).then(res => {
      if (res.status >= 300) throw new Error('auto match error');
      else return res.json()
    })
      .catch(err => {
        console.log('auto match error:', err);
        throw new Error(err)
      });
  }

  autoConnectSegments = async (url = '', orgcode = '', admin = {}, payload = {}, jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    if (Object.keys(admin).length === 0) throw new Error('No admin data found. Please refresh.');
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        orgcode,
        admin,
        payload
      })
    }).then(res => {
      if (res.status >= 300) throw new Error('segment auto connect error');
      else return res.json()
    })
      .catch(err => {
        console.log('segment auto connect error:', err);
        throw new Error(err)
      });
  }

  connectionsReport = async (url = '', orgcode = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, 'NULL')) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      type: 'connections-report'
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        orgcode
      })
    }).then(res => {
      if (res.status >= 300) throw new Error('connections report error');
      else return res.json()
    })
      .catch(err => {
        console.log('connections report error:', err);
        throw new Error(err)
      });
  }

  getTrends = async (url = '', getType = '', days = '0', orgcode = '', email = '', jwt = '') => {
    if (this.hasUndefined('NULL', jwt, email)) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: getType,
      days: days,
      orgcode: orgcode,
      email
    })
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'GET',
      headers: this._headers
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  removeConnection = async (url = '', getType = '', id = '', members = {}, matchDateTime = '', jwt = '') => {
    if (this.hasUndefined(id, jwt, 'NULL')) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: getType,
      id: id,
      matchDateTime: matchDateTime
    });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'DELETE',
      headers: this._headers,
      body: JSON.stringify(members)
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  removeUser = async (url = '', id = '', oc = '', email = '', jwt = '') => {
    if (this.hasUndefined(id, jwt, email)) throw new Error('Fetch error.');
    const params = new URLSearchParams({
      getType: 'remove'
    });

    const payload = { id, oc, email };
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  sendConnectionReminder = async (url = '', id = '', members = {}, matchDateTime = '', matchType = '', jwt = '') => {
    if (this.hasUndefined(id, jwt)) throw new Error('Missing id or jwt');
    const params = new URLSearchParams({
      type: 'connection-reminder'
    });

    const payload = { id, members, matchDateTime, matchType };
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(url + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => res.json())
      .catch(err => { throw new Error(err) });
  }

  // Subscriptions/Customer
  unsubscribe = async (url = '', subId = '', type = '', changeObject = {}, jwt = '') => {
    if (this.hasUndefined(subId, jwt)) throw new Error('Missing id or jwt');
    const payload = { type, subId, changeObject };
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  subscribe = async (url = '', cusId = '', subId = '', type = '', email = '', priceId = '', paymentMethodId = '', jwt = '') => {
    if (this.hasUndefined(cusId, jwt, email)) throw new Error('Missing id, jwt, or email');
    const payload = { type, cusId, subId, email, priceId, paymentMethodId };
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  deleteAccount = async (url = '', cusId = '', type = '', jwt = '') => {
    if (this.hasUndefined(cusId, jwt)) throw new Error('Missing id or jwt');
    const payload = { type, cusId };
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  updateAccount = async (url = '', cusId = '', type = '', updateObj = {}, jwt = '') => {

  }

  addPaymentMethod = async (url = '', cusId = '', type = '', paymentObj = {}, jwt = '') => {
    if (this.hasUndefined(cusId, jwt)) throw new Error('Missing id or jwt');
    const payload = { type, cusId, ...paymentObj };
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json()
    })
      .catch(err => { throw new Error(err) });
  }

  removePaymentMethod = async (url = '', pmId = '', cusId = '', type = '', jwt = '') => {
    if (this.hasUndefined(pmId, jwt)) throw new Error('Missing id or jwt');
    const payload = { type, pmId, cusId };
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json()
    })
      .catch(err => { throw new Error(err) });
  }

  getAccount = async (url = '', cusId = '', type = '', jwt = '') => {
    if (this.hasUndefined(cusId, jwt)) throw new Error('Missing id or jwt');
    const payload = { type, cusId };
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(url, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(async res => {
      if (res.status > 299) await res.json().then(v => { throw v; });
      return res.json();
    })
      .catch(err => { throw err; });
  }

  getCustomQuestions = async (url = '', orgcode = '', jwt = '') => {
    if (this.hasUndefined(orgcode, jwt)) throw new Error('Missing id or jwt');
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(`${url}?orgcode=${orgcode}`, {
      method: 'GET',
      headers: this._headers,
    }).then(async res => {
      if (res.status > 299) await res.json().then(v => { throw v; });
      return res.json();
    })
      .catch(err => { throw err; });
  }

  listSchedules = async (url = '', getType = '', orgcode: string, jwt: string) => {
    if (this.hasUndefined(orgcode, jwt)) throw new Error('Missing oregcode or jwt');

    this._headers['Authorization'] = `${jwt}`;

    const params = new URLSearchParams({
      getType,
      orgcode
    });

    return await fetch(url + `?${params}`, {
      method: 'GET',
      headers: this._headers,
    }).then(res => {
      this.handleStatus(res.status);
      return res.json()
    })
      .catch(err => { throw new Error(err) });
  }

  addSchedule = async (url = '', getType = '', scheduleObj = {}, jwt: string) => {
    if (this.hasUndefined(jwt)) throw new Error('Missing jwt');
    const payload = scheduleObj;
    this._headers['Authorization'] = `${jwt}`;

    const params = new URLSearchParams({
      getType
    });

    return await fetch(url + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json()
    })
      .catch(err => { throw new Error(err) });
  }

  deleteSchedule = async (url = '', getType = '', orgcode = '', scheduleName = '', jwt: string) => {
    if (this.hasUndefined(jwt)) throw new Error('Missing jwt');
    const payload = { scheduleName, orgcode };
    this._headers['Authorization'] = `${jwt}`;

    const params = new URLSearchParams({
      getType
    });

    return await fetch(url + `?${params}`, {
      method: 'DELETE',
      headers: this._headers,
      body: JSON.stringify(payload)
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

}

const apiInstance = new GwApiService();
const apiContext = createContext(apiInstance);

export default apiContext;