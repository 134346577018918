export const capitalize = (str: string | undefined) => {
  return str ? `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}` : '';
}

export const capitalizeAllFirstWords = (words: string | undefined) => {
  if(words){
    const wordsArray = words.split(' ');
    const returnPhrase = wordsArray.map(w => {
      if(['and', 'or'].includes(w)) return '';
      return `${capitalize(w)}`;
    }).join(' ').trim();
    return returnPhrase;
  }
}