import { IonButton } from '@ionic/react';
import '../../../style/ionic/Subscription.css';

interface RemovePaymentTypes {
  dismiss: (data: string) => void;
  methodsOverOne: boolean;
}

const RemovePaymentModal = ({dismiss, methodsOverOne}: RemovePaymentTypes) => {

  return (
    <section className="modal-container">
      {methodsOverOne ?
        <aside>
          <h1>Are you sure you want to delete this payment method?</h1>
          <IonButton fill="clear" color="dark" onClick={() => dismiss('dismiss')}>Cancel</IonButton>
          <IonButton onClick={() => dismiss('confirm')}>Yes</IonButton>
        </aside>
        :
        <h1 color="alert">You cannot delete your only payment method. Please add another method before removing this one.</h1>
      }
    </section>
  );
}

export default RemovePaymentModal;