import React, { useMemo } from 'react';
import { IonSelectOption } from '@ionic/react';

const MatchOptions = () => {
    const options = useMemo(() => {
        const result = [];
        for (let i = 10; i <= 500; i += (i < 100 ? 10 : 100)) {
            result.push(
                <IonSelectOption key={i} value={i}>
                    {i}
                </IonSelectOption>
            );
        }
        return result;
    }, []);
    return <>{options}</>;
};

export default MatchOptions;