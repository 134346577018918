import { useRef } from 'react';
import { IonCard, IonCardHeader, IonButton, IonLoading, IonNote, IonGrid, IonCol, IonRow } from '@ionic/react';
import Avatar from 'react-avatar';
import Sidesheet, { chosenUserTypes, SideSheetHandle } from './Sidesheet';
import { getMatchRanking, getMatchRankingColor } from '../utilities/matchLegend';
import { MemberMatchTypes, CurrentUserTypes } from './MemberMatches';
import { componentContainer, ionCardContainer, cardName } from '../style/ts/MatchComponentCSS';

interface DataMessageTypes {
  message: string
}

interface MatchComponentTypes {
  refetch: () => void
  isIdle: boolean
  data: MemberMatchTypes[] | DataMessageTypes | undefined
  user: CurrentUserTypes
  matchType: string
}

const MatchComponent = ({ refetch, isIdle, data, user, matchType }: MatchComponentTypes) => {
  const chosenUserRef = useRef<chosenUserTypes>({} as chosenUserTypes);
  const sidesheetRef = useRef<SideSheetHandle | null>(null);

  const showSidesheet = (fname: string | undefined, lname: string | undefined, points: number, id: string, orgcode: string, email: string) => {
    chosenUserRef.current = {fname: fname, lname: lname, score: points, user2Id: id, orgcode: orgcode, email2: email};
    sidesheetRef.current?.handleShow(chosenUserRef.current, user, points);
  }

  if(isIdle) return <IonButton style={{marginLeft: '2rem'}} color="dark" onClick={refetch} fill="outline">Match Up!</IonButton>
  else if (!data) return <IonLoading isOpen />
  else if ((data as DataMessageTypes).message) return <IonNote>There was an error, please try again later.</IonNote>
  else return (
    <aside style={componentContainer}>
      <Sidesheet ref={sidesheetRef} matchType={matchType} />
      {(data as MemberMatchTypes[]).map((u, index) => {
        return (
          <IonCard onClick={() => showSidesheet(u.user.M.fname.S, u.user.M.lname.S, u.points, u.id?.S as string, u.user.M.orgcode?.L[0].S as string, u.user.M.email.S as string)} style={ionCardContainer} key={index} button>
            <IonCardHeader>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg="2" sizeMd="3">
                    <Avatar color={getMatchRankingColor(u.points)} value={`${u.points}%`} size="80" round={true} />
                  </IonCol>
                  <IonCol sizeLg="7" sizeMd="6">
                    <h3 className="satoshi" style={{...cardName, marginLeft: '1rem'}}>{u.user.M.fname.S} {u.user.M.lname.S}</h3>
                    <p className="satoshi" style={{color: '#3B3B3B', marginLeft: '2rem', marginTop: '0'}}>Active Connections: {u.activeConnections.N}</p>
                  </IonCol>
                  <IonCol sizeLg="3" sizeMd="3">
                    <h6 style={{textTransform: 'capitalize', color: getMatchRankingColor(u.points)}}>{getMatchRanking(u.points)} {matchType} Match</h6>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          </IonCard>
        );
      })}
    </aside>
  );
}

export default MatchComponent;