import { IonGrid, IonRow, IonCol, IonTitle, IonCard } from '@ionic/react';
import Avatar from 'react-avatar';
import randomInt from '../../utilities/random-int';
import { TableTypes } from '../Table';
import '../Table.css';

interface SegmentTableTypes extends TableTypes {
  segmentNumber: number
}

const SegmentTable = ({membersLists, segmentNumber}: SegmentTableTypes) => {
  return (
    <IonCard>
      <IonGrid>
        {(membersLists && membersLists?.length > 0) ?
          membersLists?.map((memberList, index) => {
            return (
              <aside key={`${index}-${randomInt()}`}>
                <h2>{`Preview of Segment ${segmentNumber} (Not all members)`}</h2>
                <br />
                <p style={{paddingLeft: "0.8rem"}}>Press <strong>GO!</strong> to Auto Connect your segment(s)</p>
                {
                  memberList?.Items?.map(member => {
                    return (
                      <IonRow className="row-background" style={{borderTop: "1px solid lightgray"}} key={member.id!.S}>
                        <IonCol sizeXl="3">
                          {/* {(member.alreadyViewed && !member.alreadyViewed.BOOL) && <span className="badge satoshi">New!</span>} */}
                          <Avatar className={`${(member.alreadyViewed && !member.alreadyViewed.BOOL) && 'shiny'}`} name={`${member.user.M.fname.S} ${member.user.M.lname.S}`} size="60" round={true} />
                        </IonCol>
                        <IonCol sizeXl="4">
                          <h4 style={{textTransform: "capitalize"}}>{member.user.M.fname.S} {member.user.M.lname.S}</h4>
                        </IonCol>
                        <IonCol sizeXl="3">
                          <h4 style={{textTransform: "capitalize"}}>{member.archetype.S}</h4>
                        </IonCol>
                      </IonRow>
                    )
                  })
                }
              </aside>
            );
          }) : 
          <IonRow>
            <IonCol>
              <IonTitle>We could not find any members using your filter preferences.</IonTitle>
            </IonCol>
          </IonRow>
        }
      </IonGrid>
    </IonCard>
  );
}

export default SegmentTable;