import { useRef, useLayoutEffect, KeyboardEvent, useState } from 'react';
import { IonInput, IonLabel, IonList, IonItem, useIonAlert, IonButton } from '@ionic/react';
import { useHistory, Link } from 'react-router-dom';
import { useCognitoContext } from '../hooks/cognitoContext';
import { useAuthContext } from '../hooks/authContext';
import SimpleReactValidator from 'simple-react-validator';
import { AuthAttributeTypes } from '../services/cognito-service';
import { loginContainerMobile, loginContainer, loginBtn } from '../style/ts/LoginCSS';
import '../style/ionic/Login.css';

interface LoginTypes {
  email: string
  password: string
}

interface NewPasswordTypes {
  newPassword: string
  // newFirstName: string
  // newLastName: string
  // organization: string
}

const Login = () => {
  const cognito = useCognitoContext();
  const userAuth = useAuthContext();
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const loginValidator = useRef(new SimpleReactValidator);
  const loginValues = useRef<LoginTypes>({
    email: '',
    password: ''
  });
  const inputSectionRef = useRef<HTMLIonInputElement>(null)
  // const [reloader, setReloader] = useState(false);
  const [present] = useIonAlert();
  const authAttribtesRef = useRef<AuthAttributeTypes>({} as AuthAttributeTypes);

  useLayoutEffect(() => {
    if(userAuth.auth && userAuth.jwtRef.current) history.push('/Members');
  }, []);

  // const handleOnBlur = (val: string, property: string) => {
  //   loginValues.current = {...loginValues.current, [`${property}`]: val};
  // }
  
  const handleKeyPress = (e: KeyboardEvent) => {
    if(e.key === 'Enter') handleLogin();
  }

  const handleLogin = async () => {
    setIsButtonDisabled(true);
    if(!loginValidator.current.allValid()) {
      loginValidator.current.showMessages();
      // setReloader(r => !r);
    }

    const inputs = inputSectionRef.current?.querySelectorAll('input');

    if(inputs) {
      // set inputs here
      const inputsArray = Array.from(inputs);
      const emailInput = inputsArray.find(({name}) => name === 'email');
      loginValues.current.email = (emailInput?.value as LoginTypes['email']).trim().toLowerCase();
      const pwInput = inputsArray.find(({name}) => name === 'password');
      loginValues.current.password = (pwInput?.value as LoginTypes['password']).trim();
    }

    await cognito.authenticate(loginValues.current.email, loginValues.current.password)
      .then((res: AuthAttributeTypes | boolean) => {
          if(typeof res === 'boolean') {
            // (userAuth.login && userAuth.jwtRef.current) && userAuth.login();
            userAuth.setUser && userAuth.setUser(true);
          }
          else {
            present({
              header: 'New password',
              subHeader: 'You must change the given password to your own...',
              message: 'The password must, at minimum, be 8 characters, and must have at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.',
              cssClass: 'large-modal',
              inputs: [
                // {
                //   name: 'newFirstName',
                //   placeholder: 'First Name',
                //   type: 'text'
                // },
                // {
                //   name: 'newLastName',
                //   placeholder: 'Last Name',
                //   type: 'text'
                // },
                // {
                //   name: 'organization',
                //   placeholder: 'Organization',
                //   type: 'text'
                // },
                {
                  name: 'newPassword',
                  placeholder: 'New password',
                  type: 'password'
                }
              ],
              buttons: [
                {
                  text: 'Submit',
                  handler: (data: NewPasswordTypes) => {
                    // authAttribtesRef.current.userAttributes.given_name = data.newFirstName;
                    // authAttribtesRef.current.userAttributes.family_name = data.newLastName;
                    // authAttribtesRef.current.userAttributes['custom:organization'] = data.organization
                    cognito.completePasswordChallenge(data.newPassword, authAttribtesRef.current)
                      .then(() => {
                        alert('Password change successful 🥳');
                        // userAuth.login && userAuth.login();
                        userAuth.setUser && userAuth.setUser(true);
                        // history.push('/Members');
                        // return false;
                      }).catch(err => {
                        alert(`New password entry failed 🙀. Please try again later. ${err.message}`);
                        setIsButtonDisabled(false);
                      });
                  }
                }
              ],
              onDidDismiss: () => console.log('dismissed')
            });

            authAttribtesRef.current = res;
          } 

      })
      .catch(() => {
        alert('The email and/or password are off. Try again, please.');
        setIsButtonDisabled(false);
      });
  }

  return (
    <section ref={inputSectionRef} style={(window.innerWidth < 600) ? loginContainerMobile : loginContainer} className="satoshi container">
      <form>
        <IonList mode="ios">
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked">Email</IonLabel>
            {/* <IonInput onBlur={(e: FocusEvent<HTMLIonInputElement>) => handleOnBlur(e.target.value as string, 'email')} type="email" required placeholder="Email" name="email" /> */}
            <IonInput type="email" required placeholder="Email" name="email" />
          </IonItem>
          <IonItem>
            <IonLabel mode="ios" position="stacked">Password</IonLabel>
            {/* <IonInput onBlur={(e: FocusEvent<HTMLIonInputElement>) => handleOnBlur(e.target.value as string, 'password')} type="password" required placeholder="Password" name="password" /> */}
            <IonInput type="password" required placeholder="Password" name="password" onKeyUp={handleKeyPress} />
          </IonItem>
        </IonList>
      </form>
      <IonButton disabled={isButtonDisabled} style={loginBtn} mode="ios" onClick={handleLogin}>{isButtonDisabled ? 'Connecting' : 'Log In'}</IonButton>
      <Link style={{marginLeft: '1.5rem', display: 'block', marginTop: '1rem'}} to="/NewPassword">Forgot Password?</Link>
    </section>
  );
}

export default Login;