import { Fragment } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { exitSharp } from 'ionicons/icons';
import { useAuthContext } from '../hooks/authContext';

const LogoutButton = () => {

  const userAuth = useAuthContext();

  const logout = () => {
    userAuth.logout && userAuth.logout();
  }

  return (
    <Fragment>
      <IonButton color="dark" fill="clear" onClick={logout}>
      <IonIcon icon={exitSharp} /> &nbsp; Logout
      </IonButton>
    </Fragment>
  );
}

export default LogoutButton;