import { createContext, useState, FC, useLayoutEffect, useRef, MutableRefObject, Dispatch } from 'react';
import { useCognitoContext } from './hooks/cognitoContext';
import { useApiContext } from './hooks/apiContext';
import { urls } from './utilities/urls';
import { StringTypes } from './components/Members';
import { saveSettings, getSettings } from './utilities/localSave';
import { useHistory } from 'react-router';

export interface AuthContextTypes {
  auth: boolean
  orgcode: string | undefined
  orgname: MutableRefObject<string | undefined>
  userDetails: UserAttributeTypes | undefined
  settings: SettingsTypes | undefined
  tier: string
  logout?: () => void
  login?: () => void
  enterpriseLogin?: (orgcode: string) => void
  changeNumberTypeSetting?: (propKey: string, value: number) => void
  setUser?: Dispatch<React.SetStateAction<boolean>>
  jwtRef: MutableRefObject<string | undefined>
}

interface OrgcodeTypes {
  orgcode_org: StringTypes
  orgtier: StringTypes
}

interface CognitoAttributeTypes {
  Name: string
  Value: string
}

export interface CognitoAttributeTokenTypes {
  attrs: CognitoAttributeTypes[] | undefined
  jwt: string | undefined
}

export interface SettingsTypes {
  connectionsLimit: number | undefined
  matchesToShow: number | undefined
}

export interface UserAttributeTypes {
  'custom:organization': string
  'custom:customerId': string
  'sub': string
  'email_verified': string
  'phone_number_verified': string
  'phone_number': string
  'given_name': string
  'family_name': string
  'email': string
}

export const AuthContext = createContext<AuthContextTypes>({ auth: false, orgcode: undefined, orgname: undefined as unknown as MutableRefObject<undefined>, tier: 'bronze', userDetails: undefined, settings: undefined, jwtRef: undefined as unknown as MutableRefObject<string | undefined> });

const AuthProvider: FC = ({ children }) => {
  const cognito = useCognitoContext();
  const api = useApiContext();
  const history = useHistory();
  // TODO: All use states should be changed to single object
  const [auth, setUser] = useState(false);
  const [orgcode, setOrgcode] = useState<string | undefined>(undefined);
  const [tier, setTier] = useState<string | 'bronze' | 'gold'>('bronze');
  const savedSettings = getSettings();
  const orgname = useRef<string | undefined>(undefined);
  // default settings are set here
  const settingsRef = useRef<SettingsTypes>({ connectionsLimit: savedSettings?.connectionsLimit ?? 1, matchesToShow: savedSettings?.matchesToShow ?? 50 });
  const userRef = useRef({} as UserAttributeTypes);
  const jwtRef = useRef<string | undefined>(undefined);

  const setJwt = (returnedJwt: string) => {
    jwtRef.current = returnedJwt;
  }

  const changeNumberTypeSetting = (propKey: string, value: number) => {
    settingsRef.current[`${propKey}` as keyof SettingsTypes] = value;
    saveSettings(settingsRef.current);
  }

  const enterpriseLogin = (orgcode: string) => {
    if (!orgcode) return;
    sessionStorage.setItem('current_orgcode', orgcode);
    setOrgcode(orgcode);
    history.push('/Home');
  }

  const logout = () => {
    cognito.logout();
    setUser(false);
    window.location.reload();
  }

  // effect is done on landing and when auth changes
  useLayoutEffect(() => {
    cognito.getUserAttrs()
      .then(async (res: CognitoAttributeTokenTypes) => {
        setJwt(res.jwt!);
        userRef.current = (res.attrs as CognitoAttributeTypes[]).reduce((prev, curr) => ({ ...prev, [`${curr.Name}`]: curr.Value }), {}) as UserAttributeTypes;
        try {
          // TODO: change this from just getting orgcode to also getting templateName, fromEmail, etc. once that's ready
          const orgData: OrgcodeTypes = await api.getOrgcode(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', userRef.current.sub, jwtRef.current);
          setOrgcode(orgData.orgtier.S === 'gold' ? sessionStorage.getItem('current_orgcode') ?? orgData.orgcode_org.S : orgData.orgcode_org.S);
          // if Enterprise, take to org choice menu
          if (!auth) setUser(true);
          if (orgData.orgtier.S === 'gold') {
            setTier(orgData.orgtier.S);
          } else {
            localStorage.setItem('tier', 'bronze');
            setTier(orgData.orgtier.S ?? 'bronze');
          }
          if (!sessionStorage.getItem("current_orgcode") || !sessionStorage.getItem("current_orgname")) {
            history.push('/Orgchoice');
          } else {
            history.push(sessionStorage.getItem("last_path") || "/Home");
          }
        } catch (err) {
          alert('There seems to be a problem 😭. Please try again later.');
          setUser(u => false);
        }
      }).catch(err => console.error('user attributes error: ', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, orgcode, orgname, tier, userDetails: userRef.current, settings: settingsRef.current, logout, enterpriseLogin, changeNumberTypeSetting, setUser, jwtRef }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;