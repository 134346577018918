import { CheckboxChangeEventDetail, IonAccordion, IonAccordionGroup, IonCheckbox, IonIcon, IonItem, IonLabel, IonRadio, IonRadioGroup, RadioGroupChangeEventDetail } from '@ionic/react';
import { musicalNotes, football, airplane, paw, bicycle, compass, brush, book, people } from 'ionicons/icons';
import lc from '../data/interestsHigh.json';
import lifexpItems from '../data/lifeXpItems.json';
import { IconTypes } from './interfaces/icons';
import { IonCheckboxCustomEvent } from '@ionic/core/dist/types/components';
import '../style/ionic/InterestsAccordion.css';
import { MutableRefObject } from 'react';
import { FilterTypes } from './Enterprise/FilterBody';
import randomInt from '../utilities/random-int';

interface LifeChoicesTypes {
  name: string
  item_name: string
  icon: string
}

interface LifeXpTypes {
  item: string;
  item_name: string;
  item_choices: string[];
  required: boolean;
  input: boolean;
}

interface InterestsAccordionTypes {
  handleSelection: (selection: string, tag: string, interestName?: string) => void;
  filterChoicesRef: MutableRefObject<FilterTypes>;
}

const InterestsAccordion = ({handleSelection, filterChoicesRef}: InterestsAccordionTypes) => {
  const iconObject = {
    "musical-notes": musicalNotes,
    football: football,
    airplane: airplane,
    paw: paw,
    bicycle: bicycle,
    compass: compass,
    brush: brush,
    book: book,
    contacts: people
  } as IconTypes;

  return (
    <IonAccordion>
      <IonItem slot="header">
        <IonLabel>Interests</IonLabel>
      </IonItem>
      <aside className="ion-padding" slot="content">
        
        <IonAccordionGroup>
          {
            (lc as LifeChoicesTypes[]).map(({name, item_name, icon}) => {
              if(['travel', 'volunteer', 'reading'].includes(item_name)) {
                return (
                  <IonItem key={`${item_name}-${randomInt()}`}>
                    <IonIcon size="small" style={{marginRight: "0.3rem"}} icon={iconObject[`${icon}` as keyof IconTypes]} />
                    <IonLabel style={{textTransform: "capitalize"}}>{item_name}</IonLabel>
                    <IonCheckbox checked={filterChoicesRef.current.topInterests.includes(`${item_name}-5`)} slot="start" value={item_name} onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => handleSelection(e.detail.value, 'topInterests')} />
                  </IonItem>
                );
              } else {
                return (
                  <IonAccordion key={`${item_name}-${randomInt()}`}>
                    <IonItem slot="header">
                      <IonLabel style={{textTransform: "capitalize"}}>
                      <IonIcon style={{marginRight: "0.3rem"}} icon={iconObject[`${icon}` as keyof IconTypes]} />
                        {name}
                      </IonLabel>
                    </IonItem>
                    <aside className="ion-padding" slot="content">
                      {(() => {
                        const expItem = lifexpItems.find(i => i.item_name === item_name)! as LifeXpTypes;
                        if(!expItem) return;
                        const { item_choices } = expItem;
                        return item_choices.map((i, index) => (
                          <IonItem key={`${index}-${randomInt()}`}>
                            <IonCheckbox checked={filterChoicesRef.current.interests.includes(`${item_name}-${i.toLowerCase()}`)} slot="start" value={i.toLowerCase()} onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => handleSelection(e.detail.value, 'interests', item_name)} />
                            <IonLabel>{i}</IonLabel>
                          </IonItem>
                        ))
                      })()
                      }
                    </aside>
                  </IonAccordion>
                );
              }
            })
          }
        </IonAccordionGroup>
      </aside>
    </IonAccordion>
  );
}

export default InterestsAccordion;