import { CSSProperties } from 'react';

export const loginContainer: CSSProperties = {
  margin: '6rem 12rem'
}

export const loginContainerMobile: CSSProperties = {
  margin: '2rem 3rem'
}

export const loginBtn: CSSProperties = {
  marginTop: '2rem',
  marginLeft: '1rem'
}