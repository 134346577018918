import { CSSProperties } from 'react';

export const componentContainer: CSSProperties = {
  width: '90%'
};

export const ionCardContainer: CSSProperties = {
  marginBottom: '2.2rem',
  boxShadow: '0 14px 50px 12px rgba(167, 164, 164, 0.3)',
  borderRadius: '1.2rem'
};

export const cardName: CSSProperties = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  paddingLeft: '1rem',
  textTransform: 'capitalize'
};