import { IonRow, IonCol, IonList, IonItem } from '@ionic/react';

interface ProfileCardTypes {
  title: string
  subtitle?: string
  body?: string
  list?: {[key: string]: string | undefined}[]
}
const ProfileCard = ({title, subtitle, body, list}: ProfileCardTypes) => {
  return (
    <>
      <IonRow>
        <IonCol push="1" sizeLg="12">
          <h2>{title}</h2>
          {subtitle && <h6 style={{textTransform: "uppercase"}}>Active Connections: {subtitle}</h6>}
        </IonCol>
      </IonRow>
      <IonRow>
      <IonCol push="1" sizeLg="12">
        {(list && list.length > 0) &&
          <IonList>
            {list.map((i, index) => {
              const keyValueArray = Object.entries(i);
              const transform = keyValueArray[0][0] === 'email' ? 'lowercase' : 'capitalize';
              return (
                <IonItem style={{fontFamily: 'Satoshi-Regular, sans-serif'}} key={index}>
                  <strong style={{textTransform: "capitalize"}}>{`${keyValueArray[0][0]}`}</strong> :&nbsp;<span style={{textTransform: transform}}>{keyValueArray[0][1]}</span>
                </IonItem>
              );
            })}
          </IonList>
        }
        {body &&
          <p>{body}</p>
        }
        </IonCol>
      </IonRow>
    </>
  );
}

export default ProfileCard;