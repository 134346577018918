import { IonButton, IonCol, IonGrid, IonRow, IonText, IonTitle } from '@ionic/react';
import Avatar from 'react-avatar';
// import { useQueryClient } from 'react-query';
// import { useAuthContext } from '../../hooks/authContext';
import { MemberTypes } from '../Members';
import '../../style/ionic/Segmentation.css';

interface ConnectedReturnTypes {
  message: string
  notFullyConnectedArray: MemberTypes[]
  unfinished: boolean
}

interface FinishedTypes {
  connectedReturn: ConnectedReturnTypes | string
  dismissModal: (data?: any, role?: string | undefined) => void
}

const FinishedBody = ({connectedReturn, dismissModal}: FinishedTypes) => {
  return (
    <section style={{overflowX: "scroll", padding: "2rem"}}>
      <IonGrid>
        { (typeof connectedReturn === 'string') ?
        <aside style={{padding: "1.5rem"}}>
          <IonTitle>{connectedReturn}</IonTitle>
        </aside>
        :
        <aside style={{padding: "1.5rem"}}>
          {/* <IonTitle size="large">Members that were not fully connected:</IonTitle> */}
          <h1>Auto segment connection underway!</h1>
          <br />
          {
            (connectedReturn.notFullyConnectedArray?.length > 0) ?
            (connectedReturn.notFullyConnectedArray as MemberTypes[]).map((member) => {
              return (
                <IonRow className="row-background" style={{borderTop: "1px solid lightgray"}} key={member.id!.S}>
                  <IonCol sizeXl="3">
                    <Avatar name={`${member.user.M.fname.S} ${member.user.M.lname.S}`} size="60" round={true} />
                  </IonCol>
                  <IonCol sizeXl="4">
                    <h4 style={{textTransform: "capitalize"}}>{member.user.M.fname.S} {member.user.M.lname.S}</h4>
                  </IonCol>
                </IonRow>
              );
            }
            )
            :
            <IonRow>
              <IonCol>
                <h3>Depending on parameters you have set, there may be some members that may not be connected. We recommend you review the connections column in the <IonText color="tertiary"><strong>Members</strong></IonText> section.</h3>
                <p><span role="img" aria-label="warning">⚠️</span> Please allow a couple of minutes for our robots to finish the segmentation connection job. <strong>In the meantime, here's a fun fact</strong>:</p>
                <aside className="p-bg"><p>Despite its size, El Salvador <span role="img" aria-label="el-salvador-flag">🇸🇻</span> has a rich history. The territory was once occupied by Olmecs, followed by the Mayans. At the end of Mayan rule, the Toltec Empire took control of the country.</p></aside>
                <p>Isn't that fascinating?</p>
              </IonCol>
            </IonRow>
          }
        </aside>
        }
        <IonRow>
          <IonCol style={{padding: "1.5rem"}}>
            <IonButton size="large" fill="outline" className="filter" onClick={dismissModal}>Dismiss</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </section>
  );
}

export default FinishedBody;
