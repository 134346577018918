import { useMutation } from 'react-query';
import { useApiContext } from '../../hooks/apiContext';
import { IonItem, IonLabel, IonList, IonDatetime, IonSelect, IonSelectOption, IonButton, DatetimeChangeEventDetail, SelectChangeEventDetail } from '@ionic/react';
import { IonDatetimeCustomEvent, IonSelectCustomEvent } from '@ionic/core';
import { AutoConnectSettingsTypes, Settings } from '../interfaces/autoConnectSchedule';
import { urls } from '../../utilities/urls';
import { useRef } from 'react';
import { AuthContextTypes } from '../../AuthProvider';
import AutoConnectList from './AutoConnectList';

interface AutoConnectScheduleTypes {
  settings: Settings,
  auth: AuthContextTypes,
}

const AutoConnectSchedule = ({ settings, auth }: AutoConnectScheduleTypes) => {

  const api = useApiContext();

  const scheduleSettingsRef = useRef<AutoConnectSettingsTypes>({
    orgcode: auth.orgcode as string,
    orgname: auth.orgname?.current as string ?? "No Orgname",
    timezone: "America/Chicago",
    datetime: new Date().toISOString(),
    settings
  });

  const handleDatetime = (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
    console.log(new Date(e.detail.value as string).toISOString());
    scheduleSettingsRef.current.datetime = new Date(e.detail.value as string).toISOString();
  }

  const handleTimezone = (e: IonSelectCustomEvent<SelectChangeEventDetail<any>>) => {
    console.log(e.target.value);
    scheduleSettingsRef.current.timezone = e.target.value;
    console.log(scheduleSettingsRef.current);
  }

  const timeZones = [
    // Western Hemisphere
    'America/New_York',      // Eastern Time - US & Canada
    'America/Chicago',       // Central Time - US & Canada
    'America/Denver',        // Mountain Time - US & Canada
    'America/Los_Angeles',   // Pacific Time - US & Canada
    'America/Anchorage',     // Alaska Time - US & Canada
    'America/Phoenix',       // Mountain Standard Time - Arizona
    'America/Adak',          // Hawaii-Aleutian Standard Time
    'America/Argentina/Buenos_Aires', // Argentina Time
    'America/Sao_Paulo',     // Brasilia Time - Brazil
    'America/Mexico_City',   // Central Standard Time - Mexico
    'America/Bogota',        // Colombia Time
    'America/Havana',        // Cuba Standard Time
    'America/Lima',          // Peru Time
    'America/Montevideo',    // Uruguay Time
    'America/El_Salvador',   // El Salvador Time

    // Western Europe
    'Europe/London',         // Greenwich Mean Time - UK
    'Europe/Dublin',         // Irish Standard Time
    'Europe/Lisbon',         // Western European Time - Portugal
    'Europe/Madrid',         // Central European Time - Spain
    'Europe/Paris',          // Central European Time - France
    'Europe/Berlin',         // Central European Time - Germany
    'Europe/Rome',           // Central European Time - Italy
    'Europe/Amsterdam',      // Central European Time - Netherlands
    'Europe/Brussels',       // Central European Time - Belgium
    'Europe/Vienna',         // Central European Time - Austria
    'Europe/Zurich'          // Central European Time - Switzerland
  ];

  const { mutate, data, isLoading, isSuccess, reset } = useMutation(async (scheduleSettings: AutoConnectSettingsTypes) => {
    // TEMPORARY FIX
    const addAdminToSettings = { ...scheduleSettings, admin: settings }
    return await api.addSchedule(process.env.NODE_ENV !== 'production' ? urls.dashboard : '/v1/dashboard', 'scheduler', addAdminToSettings, auth.jwtRef.current!)
  })

  if (isSuccess) {
    return (
      <section>
        <h1>{data}</h1>
        <hr />
        <IonButton onClick={reset}>Refresh</IonButton>
      </section>
    )
  }
  else return (
    <section>
      <h1>Set up your auto connect schedule</h1>
      <IonList lines="none">
        <IonItem>
          <IonLabel position="stacked">Currently Scheduled</IonLabel>
          <AutoConnectList auth={auth} api={api} />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Date/Time</IonLabel>
          <IonDatetime onIonChange={handleDatetime} minuteValues="0,15,30,45" min={scheduleSettingsRef.current.datetime} max={(new Date(scheduleSettingsRef.current.datetime).getFullYear() + 2).toString()} presentation="date-time" />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Time Zone</IonLabel>
          <IonSelect onIonChange={handleTimezone} value={scheduleSettingsRef.current.timezone}>
            {timeZones.map(tz => <IonSelectOption key={tz} value={tz}>{tz}</IonSelectOption>)}
          </IonSelect>
        </IonItem>
      </IonList>
      <IonButton disabled={(isLoading || isSuccess)} onClick={() => {
        scheduleSettingsRef.current = {
          ...scheduleSettingsRef.current,
          settings: settings,
        }
        mutate(scheduleSettingsRef.current)
      }}>{isLoading ? (isSuccess ? 'Click Reload' : 'Working...') : 'Schedule'}</IonButton>
    </section>
  );
}
export default AutoConnectSchedule;